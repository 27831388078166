import { SetStateAction } from 'react';

import { TFunction } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Dispatch } from 'redux';

import { BalanceSheetAccountRows } from './components/BalanceSheet';
import { TaxPeriodShape } from './models';

import { ReactComponent as LinkIcon } from '../../assets/img/Icon_Link.svg';
import {
  calculateBalanceSheetForDeferred,
  calculateDeferredSum,
  calculateEndingBalanceForCredits,
  calculateIncomeStatementForDeferred,
  calculateReturnToProvision,
  calculateAdjustedPtbi
} from '../../calculations';
import { LEVELS, NULL_UUID, reservedNames } from '../../constants';
import {
  Entity,
  FinancialInfo,
  FinancialInfoTabsData,
  Level,
  Step,
  Jurisdiction,
  SubJurisdiction,
  FinancialInfoValue,
  FinancialRowForDeletion,
  CompletionStatus
} from '../../models';
import {
  financialDataOnUpdatedFailedCells,
  createFinancialData,
  updateFinancialData,
  deleteFinancialData,
  deleteStateRTPTaxProvisionCol
} from '../../redux/financialData';
import { setUploadManagerCreditName, setUploadManagerIsOpen } from '../../redux/uploadManager';
import {
  findAdjustment,
  findStateMap,
  formatSubJurisdictionForDisplay,
  sortOnColumn,
  addNullAccountIdToRowsWhichDoNotHaveAccountId,
  RowForGetTableDataFromFinancialData,
  GetTableDataFromFinancialDataReturnType,
  turnToObjById
} from '../../utils';
import { EditableNumericCell } from '../Table/components';
import { Column, FailedCells, TableProps, Row } from '../Table/Table.proptype';
import { renderValue } from '../Table/utils';

export type BalanceSheetAccountShape = {
  accountId?: string;
  accountNumber?: number;
  name?: string;
  beginningBalance?: number;
  beginningPayments?: number;
  endingBalance?: number;
  endingPayments?: number;
};

export type IncomeStatementAccountShape = {
  accountId?: string;
  bookBalance?: number;
  comments?: string | null;
  isNew?: boolean;
  m1Adjustment?: number;
  name?: string;
  taxBalance?: number;
  note?: string;
};

export type RTPAccountShape = {
  accountId?: string;
  rowId: string;
  name: string;
  taxProvision: number;
  taxReturn: number;
  comments?: string;
};
export type StateRTPShape = {
  isCompleted?: boolean;
  permanent: RTPAccountShape[];
  temporary: RTPAccountShape[];
  preApportionedNetOperatingLoss: RTPAccountShape;
  apportionmentFactor: RTPAccountShape;
  postApportionedNetOperatingLoss: RTPAccountShape;
  credits: RTPAccountShape[];
  taxEffectedAdjustments: RTPAccountShape[];
};

export type StateModificationShape = {
  id: string;
  name: string;
  accountId?: string;
  amount?: number;
  comment?: string;
};

export type DeferredAccountShape = {
  accountId?: string;
  name: string;
  beginningBalance?: number;
  deferredOnlyAdjustment?: number;
  balanceSheetOnlyAdjustment?: number;
  manualEndingBalance?: number;
  comments?: string;
};

export type ValuationAllowanceInnerShape = {
  beginningBalance?: number;
  m1Adjustment?: number;
  manualEndingBalance?: number;
};

export type ValuationAllowanceShape = {
  temporary: ValuationAllowanceInnerShape;
  netOperatingLoss: ValuationAllowanceInnerShape;
  credits: ValuationAllowanceInnerShape;
  taxEffected: ValuationAllowanceInnerShape;
};

export type PermanentAdjustmentShape = {
  accountId?: string;
  amount?: number;
  comments?: string | null;
  disallowedPct?: number;
  isNew?: boolean;
  m1Adjustment?: number;
  name?: string;
  note?: string;
};

export type FederalShape = {
  rtp?: {
    temporary: RTPAccountShape[];
    netOperatingLoss?: RTPAccountShape;
    credits: RTPAccountShape[];
  };
  permanent?: PermanentAdjustmentShape[];
  temporary?: {
    balanceSheet: BalanceSheetAccountShape[];
    incomeStatement: IncomeStatementAccountShape[];
  };
  netOperatingLosses?: TaxPeriodShape[];
  credits?: TaxPeriodShape[];
  deferred?: {
    temporary?: DeferredAccountShape[];
    netOperatingLoss?: DeferredAccountShape;
    credits?: DeferredAccountShape[];
  };
};

export type ApportionShape = {
  amount?: number;
  note?: string;
};

export type AdjustmentShape = {
  name: string;
  accountId?: string;
};

export type AdjustmentWithCategoryShape = {
  name?: string;
  accountId?: string;
  category: {
    jurisdictionLevel?: Level;
    name?: 'temporary' | 'permanent' | 'ptbi' | 'tax-effected' | 'modifications';
  };
};

export type CategoriesShape = {
  federal?: {
    temporary?: AdjustmentShape[];
    permanent?: AdjustmentShape[];
  };
  state?: {
    'tax-effected'?: AdjustmentShape[];
  };
};

export type FederalTemporaryCategoryShape = Array<{
  accountId?: string;
  name: string;
  entities: Array<{
    id: string;
    beginningBalance?: number;
    beginningPayments?: number;
    endingBalance?: number;
    endingPayments?: number;
    comments?: string;
    rtp?: RTPAccountShape;
  }>;
}>;

export type FederalPermanentCategoryShape = Array<{
  accountId?: string;
  name: string;
  entities: Array<{
    id: string;
    amount?: number;
    disallowedPct?: number;
    comments?: string;
  }>;
}>;

export type FederalTaxEffectedCategoryShape = Array<{
  name: string;
  entities: Array<{
    id: string;
  }>;
}>;

export type StateModificationsCategoryShape = Array<{
  name: string;
  isPermanent?: boolean;
  modifications: Array<{
    id: string;
    isPermanent?: boolean;
    federalAmount?: number;
    amount?: number;
    comments?: string;
    subJurisdictionIds: string[];
  }>;
}>;

type ColumnWithField = Column & { field: string };

export const VALUATION_ALLOWANCE_ROW_NAME = 'Valuation Allowance';

// eslint-disable-next-line max-params
export function makeSectionTitle(
  title: string,
  options?: any,
  dispatch?: Dispatch,
  className?: string,
  creditName?: string
) {
  return {
    sectionHeader: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h3" component="h4">
          {title}
        </Typography>
        {options?.withImportLink ? (
          <Button
            className={className}
            aria-label="link button"
            onClick={() => {
              dispatch?.(setUploadManagerIsOpen(true));
              dispatch?.(setUploadManagerCreditName(creditName));
            }}
          >
            <LinkIcon />
          </Button>
        ) : null}
      </div>
    )
  };
}

export const makeSectionTitleWithCreditName = ({
  className,
  creditName,
  dispatch,
  options,
  title
}: {
  creditName?: string;
  title: string;
  options?: any;
  dispatch?: Dispatch;
  className?: string;
}) => {
  return makeSectionTitle(title, options, dispatch, className, creditName);
};

export const emptySectionHeader = { sectionHeader: ' ', categorizable: false };

const applyDeferredData = (objArr: Array<Record<string, any>>, deferred: Row[], filterAdjustmentsByRowId = false) => {
  const result = [];
  for (const obj of objArr) {
    const deferredRow =
      deferred.find((row: Row) =>
        filterAdjustmentsByRowId
          ? (row.creditName.includes('balanceSheet') && row.sourceRowId === obj.sourceRowId) ||
            row.sourceRowId === obj.rowId
          : row.name === obj.name && (row.creditName === obj.creditName || !row.creditName)
      ) ?? {};
    const data = { ...obj, ...deferredRow, accountId: NULL_UUID, accountNumber: obj?.accountNumber };
    data.accountNumber = filterAdjustmentsByRowId ? obj?.accountNumber : undefined;
    result.push(data);
  }

  return result;
};

const mergeWithRtpRow = (row: any, rtp: Row[], filterAdjustmentsByRowId = false) => {
  const rtpRow =
    rtp.find((rtpRow) =>
      filterAdjustmentsByRowId
        ? rtpRow.sourceRowId === row.rowId
        : rtpRow.name === row.name && (rtpRow.creditName === row.creditName || !rtpRow.creditName)
    ) ?? {};
  return {
    ...row,
    ...rtpRow
  };
};

// eslint-disable-next-line max-params
export function computeFederalGrossTemporaryDifference(
  t: TFunction,
  columns: any,
  balanceSheet: any,
  incomeStatement: any,
  rtp: any,
  deferred: any,
  valuationAllowance: any,
  stepCompletionStatus?: boolean,
  dispatch?: Dispatch,
  className?: string,
  filterAdjustmentsByRowId = false
) {
  incomeStatement = incomeStatement.map((row: Row) => ({
    ...calculateIncomeStatementForDeferred({
      ...findAdjustment(row, filterAdjustmentsByRowId, rtp),
      ...row
    }),
    beginningBalance: 0,
    rtp: calculateReturnToProvision(
      mergeWithRtpRow({ ...row, creditName: 'federal.temporary.incomeStatement' }, rtp, filterAdjustmentsByRowId)
    ).rtp,
    name: row.name,
    creditName: 'federal.temporary.incomeStatement',
    accountNumber: row.accountNumber
  }));
  incomeStatement = applyDeferredData(incomeStatement, deferred, filterAdjustmentsByRowId);

  balanceSheet = balanceSheet.map((row: any) => ({
    ...calculateBalanceSheetForDeferred({
      ...findAdjustment(row, filterAdjustmentsByRowId, rtp?.temporary),
      ...mergeWithRtpRow({ ...row, creditName: 'federal.temporary.balanceSheet' }, rtp, filterAdjustmentsByRowId)
    }),
    beginningBalance: 0,
    manualEndingBalance: row.endingBalance,
    // User entries
    ...findAdjustment(row, filterAdjustmentsByRowId, deferred?.temporary),
    name: row.name,
    creditName: 'federal.temporary.balanceSheet',
    nonEditableFields: ['manualEndingBalance'],
    accountNumber: row.accountNumber
  }));

  balanceSheet = applyDeferredData(balanceSheet, deferred, filterAdjustmentsByRowId);
  const temporaryTotal = calculateDeferredSum({ data: [...incomeStatement, ...balanceSheet] });

  const federalTempAdjustments = sortOnColumn([...incomeStatement, ...balanceSheet], columns[0]);

  return makeFederalGrossTemporaryDifferenceRows({
    federalTempAdjustments,
    stepCompletionStatus,
    t,
    temporaryTotal,
    valuationAllowance,
    dispatch,
    className
  });
}

// eslint-disable-next-line max-params
export function computeStateGrossTemporaryDifference(
  t: TFunction,
  columns: any,
  balanceSheet: any,
  incomeStatement: any,
  rtp: any,
  deferred: any,
  federalDeferred: any,
  valuationAllowance: any,
  stepCompletionStatus?: boolean,
  dispatch?: Dispatch,
  className?: string
) {
  incomeStatement = incomeStatement.map((row: Row) => {
    const { accountNumber, name, creditName } = row;
    const deferredRow = deferred.find((def: any) => def.name === name) || {};
    const matchingFederalDeferredRow =
      federalDeferred.find((fed: any) => fed.name === name && (fed.creditName === creditName || !creditName)) || {};

    const {
      beginningBalance = 0,
      balanceSheetOnlyAdjustment = 0,
      deferredOnlyAdjustment = 0,
      manualEndingBalance = 0
    } = matchingFederalDeferredRow;

    const { oci, goodwill, fin48 } = deferredRow;

    return {
      ...calculateIncomeStatementForDeferred({
        ...findAdjustment(row, rtp?.temporary),
        ...row
      }),
      accountId: NULL_UUID,
      beginningBalance,
      balanceSheetOnlyAdjustment,
      deferredOnlyAdjustment,
      manualEndingBalance,
      oci,
      goodwill,
      fin48,
      rtp: calculateReturnToProvision(mergeWithRtpRow({ ...row, creditName: 'federal.temporary.incomeStatement' }, rtp))
        .rtp,
      name,
      accountNumber,
      creditName: 'federal.temporary.incomeStatement',
      nonEditableFields: [
        'beginningBalance',
        'deferredOnlyAdjustment',
        'balanceSheetOnlyAdjustment',
        'manualEndingBalance'
      ]
    };
  });

  balanceSheet = balanceSheet.map((row: Row) => {
    const { accountNumber, name, creditName } = row;
    const deferredRow = deferred.find((def: any) => def.name === name) || {};
    const matchingFederalDeferredRow =
      federalDeferred.find((fed: Row) => fed.name === name && (fed.creditName === creditName || !creditName)) || {};

    const {
      beginningBalance = 0,
      balanceSheetOnlyAdjustment = 0,
      deferredOnlyAdjustment = 0
    } = matchingFederalDeferredRow;

    const { endingBalance: manualEndingBalance = 0 } = row;

    const { oci, goodwill, fin48 } = deferredRow;

    return {
      ...calculateBalanceSheetForDeferred({
        ...findAdjustment(row, rtp?.temporary),
        ...mergeWithRtpRow({ ...row, creditName: 'federal.temporary.balanceSheet' }, rtp)
      }),
      accountId: NULL_UUID,
      beginningBalance,
      balanceSheetOnlyAdjustment,
      deferredOnlyAdjustment,
      manualEndingBalance,
      oci,
      goodwill,
      fin48,
      name,
      accountNumber,
      creditName: 'federal.temporary.balanceSheet',
      nonEditableFields: [
        'beginningBalance',
        'deferredOnlyAdjustment',
        'balanceSheetOnlyAdjustment',
        'manualEndingBalance'
      ]
    };
  });

  const temporaryTotal = calculateDeferredSum({ data: [...incomeStatement, ...balanceSheet] });

  const federalTempAdjustments = sortOnColumn([...incomeStatement, ...balanceSheet], columns[0]);

  return makeFederalGrossTemporaryDifferenceRows({
    federalTempAdjustments,
    stepCompletionStatus,
    t,
    temporaryTotal,
    valuationAllowance,
    dispatch,
    className
  });
}

const makeFederalGrossTemporaryDifferenceRows = ({
  federalTempAdjustments,
  stepCompletionStatus,
  t,
  temporaryTotal,
  valuationAllowance,
  dispatch,
  className
}: any) => [
  makeSectionTitleWithCreditName({
    title: t('Gross Temporary Differences'),
    options: { withImportLink: true, stepCompletionStatus },
    dispatch,
    className,
    creditName: 'federal.temporary'
  }),
  ...federalTempAdjustments,
  {
    isTotal: true,
    name: t('Total Gross Temporary Differences'),
    ...temporaryTotal,
    categorizable: false
  },
  emptySectionHeader,
  {
    name: t('Valuation Allowance'),
    isEditable: !stepCompletionStatus,
    nonEditableFields: ['endingBalance', 'difference'],
    ...valuationAllowance.grossTemporaryDifferences,
    creditName: 'federal.temporary',
    categorizable: false
  },
  {
    isTotal: true,
    name: t('Gross Temporary Differences, Net of Valuation Allowance'),
    ...calculateDeferredSum({ data: [temporaryTotal, valuationAllowance.grossTemporaryDifferences] }),
    categorizable: false
  }
];

export function getAttributeColumns(t: TFunction, isEditable: Column['isEditable'] = true) {
  return [
    {
      field: 'name',
      headerName: t('Tax Period'),
      isNewRowEditable: true
    },
    {
      field: 'beginningBalance',
      headerName: t('Beginning Balance'),
      isEditable,
      isNumber: true
    },
    {
      field: 'rtp',
      headerName: t('Return to Provision'),
      isEditable,
      isNumber: true
    },
    {
      field: 'generatedAmount',
      headerName: t('Amount Generated in Current Year'),
      isEditable,
      isNumber: true
    },
    {
      field: 'usedAmount',
      headerName: t('Amount (Used) in Current Year'),
      isEditable,
      isNumber: true
    },
    {
      field: 'deferredOnlyAdjustment',
      headerName: t('Deferred Only Adjustment'),
      isEditable,
      isNumber: true
    },
    {
      field: 'balanceSheetOnlyAdjustment',
      headerName: t('Balance Sheet Only Adjustment'),
      isEditable,
      isNumber: true
    },
    {
      field: 'endingBalance',
      headerName: t('Ending Balance'),
      getValue: (row: any) =>
        'endingBalance' in row ? row.endingBalance : calculateEndingBalanceForCredits(row).endingBalance,
      isNumber: true
    },
    {
      field: 'remaining',
      headerName: t('Carry Over Period Remaining'),
      isEditable,
      isNumber: true
    }
  ];
}

export function getStateNolColumns(t: TFunction): ColumnWithField[] {
  return [
    {
      field: 'name',
      headerName: t('Tax Period')
    },
    {
      field: 'beginningBalance',
      headerName: t('Beginning Balance'),
      isNumber: true
    },
    {
      field: 'rtp',
      headerName: t('Return to Provision'),
      isNumber: true
    },
    {
      field: 'generatedAmount',
      headerName: t('Amount Generated in Current Year'),
      isNumber: true
    },
    {
      field: 'usedAmount',
      headerName: t('Amount (Used) in Current Year'),
      isNumber: true
    },
    {
      field: 'deferredOnlyAdjustment',
      headerName: t('Deferred Only Adjustment'),
      isNumber: true
    },
    {
      field: 'balanceSheetOnlyAdjustment',
      headerName: t('Balance Sheet Only Adjustment'),
      isNumber: true
    },
    {
      field: 'remaining',
      headerName: t('Carry Over Period Remaining'),
      isNumber: true
    }
  ];
}

export function getRTPAttributeColumns(
  t: TFunction,
  isEditable: Column['isEditable'] = true,
  shouldShowRtpIcon = false,
  renderCellwithRtpColumnIcons: any = null
) {
  return [
    { field: 'name', width: '20%', renderCell: renderCellwithRtpColumnIcons },
    {
      field: 'taxProvision',
      headerName: t('Tax Provision'),
      isEditable,
      isNumber: true,
      width: '15%',
      shouldShowRtpIcon,
      renderCell: renderCellwithRtpColumnIcons
    },
    {
      field: 'taxReturn',
      headerName: t('Tax Return'),
      isEditable,
      isNumber: true,
      width: '15%',
      shouldShowRtpIcon,
      renderCell: renderCellwithRtpColumnIcons
    },
    {
      field: 'variance',
      headerName: t('Variance'),
      getValue: (row: any) => ('rtp' in row ? row.rtp : calculateReturnToProvision(row).rtp),
      isNumber: true,
      width: '15%',
      shouldShowRtpIcon,
      renderCell: renderCellwithRtpColumnIcons
    }
  ];
}

export function getTemporaryBalanceSheetColumns(t: TFunction, stepCompletionStatusValue: boolean) {
  return [
    {
      headerGroup: t('Beginning Balance'),
      field: 'beginningBalance',
      headerName: t('Balance'),
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerGroup: t('Beginning Balance'),
      field: 'beginningPayments',
      headerName: t('Payments'),
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerGroup: t('Beginning Balance'),
      field: 'rtp',
      headerName: t('RTP'),
      isNumber: true
    },
    {
      headerGroup: t('Beginning Balance'),
      headerName: t('Adjusted'),
      field: 'beginningAdjusted',
      divider: true,
      isNumber: true
    },
    {
      headerGroup: t('Ending Balance'),
      field: 'endingBalance',
      isEditable: !stepCompletionStatusValue,
      headerName: t('Balance'),
      isNumber: true
    },
    {
      headerGroup: t('Ending Balance'),
      field: 'endingPayments',
      headerName: t('Payments'),
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerGroup: t('Ending Balance'),
      headerName: t('Adjusted'),
      field: 'endingAdjusted',
      divider: true,
      isNumber: true
    },
    {
      headerName: t('M-1 Adjustment'),
      field: 'm1Adjustment',
      isNumber: true
    }
  ];
}

export function getTemporaryBalanceSheetColumnsForUpload(t: TFunction): ColumnWithField[] {
  return [
    {
      field: 'accountNumber',
      headerName: t('Trial Balance')
    },
    {
      field: 'name',
      headerName: t('Account Description')
    },
    {
      field: 'beginningBalance',
      headerName: t('Beginning Balance'),
      isNumber: true
    },
    {
      field: 'beginningPayments',
      headerName: t('Beginning Payments'),
      isNumber: true
    },
    {
      field: 'endingBalance',
      headerName: t('Ending Balance'),
      isNumber: true
    },
    {
      field: 'endingPayments',
      headerName: t('Ending Payments'),
      isNumber: true
    }
  ];
}

export function getDeferredColumns(
  t: TFunction,
  stepCompletionStatusValue?: boolean,
  renderAdjNameWithAccNumCell?: any
) {
  return [
    { headerName: t('Item'), field: 'name', renderCell: renderAdjNameWithAccNumCell },
    {
      headerName: t('Beginning Balance'),
      field: 'beginningBalance',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('Return to Provision'),
      field: 'rtp',
      isNumber: true
    },
    {
      headerName: t('Activity'),
      field: 'm1Adjustment',
      isNumber: true
    },
    {
      headerName: t('Deferred Only Adjustment'),
      field: 'deferredOnlyAdjustment',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('Balance Sheet Only Adjustment'),
      field: 'balanceSheetOnlyAdjustment',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('Ending Balance'),
      field: 'endingBalance',
      isNumber: true
    },
    {
      headerName: t('Ending Balance per Trial Balance / Workpaper'),
      field: 'manualEndingBalance',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('Difference'),
      field: 'difference',
      isNumber: true
    }
  ];
}

export function getDeferredColumnsFlagged(
  t: TFunction,
  stepCompletionStatusValue?: boolean,
  renderAdjNameWithAccNumCell?: any
) {
  return [
    { headerName: t('Item'), field: 'name', renderCell: renderAdjNameWithAccNumCell },
    {
      headerName: t('Beginning Balance'),
      field: 'beginningBalance',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('Return to Provision'),
      field: 'rtp',
      isNumber: true
    },
    {
      headerName: t('Activity'),
      field: 'm1Adjustment',
      isNumber: true
    },
    {
      headerName: t('Deferred Only Adjustment'),
      field: 'deferredOnlyAdjustment',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('OCI'),
      field: 'oci',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('Goodwill'),
      field: 'goodwill',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('FIN48'),
      field: 'fin48',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('Balance Sheet Only Adjustment'),
      field: 'balanceSheetOnlyAdjustment',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('Ending Balance'),
      field: 'endingBalance',
      isNumber: true
    },
    {
      headerName: t('Ending Balance per Trial Balance / Workpaper'),
      field: 'manualEndingBalance',
      isEditable: !stepCompletionStatusValue,
      isNumber: true
    },
    {
      headerName: t('Difference'),
      field: 'difference',
      isNumber: true
    }
  ];
}

// Separating due to the fixed layout
export function getDeferredColumnsVirtual(t: TFunction, stepCompletionStatusValue?: boolean) {
  return [
    { headerName: t('Item'), field: 'name', width: 150 },
    {
      headerName: t('Beginning Balance'),
      field: 'beginningBalance',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Return to Provision'),
      field: 'rtp',
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Activity'),
      field: 'm1Adjustment',
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Deferred Only Adjustment'),
      field: 'deferredOnlyAdjustment',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Balance Sheet Only Adjustment'),
      field: 'balanceSheetOnlyAdjustment',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Ending Balance'),
      field: 'endingBalance',
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Ending Balance per Trial Balance / Workpaper'),
      field: 'manualEndingBalance',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Difference'),
      field: 'difference',
      isNumber: true,
      width: 100
    }
  ];
}

// Separating due to the fixed layout
export function getDeferredColumnsVirtualFlagged(t: TFunction, stepCompletionStatusValue?: boolean) {
  return [
    { headerName: t('Item'), field: 'name', width: 150 },
    {
      headerName: t('Beginning Balance'),
      field: 'beginningBalance',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Return to Provision'),
      field: 'rtp',
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Activity'),
      field: 'm1Adjustment',
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Deferred Only Adjustment'),
      field: 'deferredOnlyAdjustment',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('OCI'),
      field: 'oci',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Goodwill'),
      field: 'goodwill',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('FIN48'),
      field: 'fin48',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Balance Sheet Only Adjustment'),
      field: 'balanceSheetOnlyAdjustment',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Ending Balance'),
      field: 'endingBalance',
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Ending Balance per Trial Balance / Workpaper'),
      field: 'manualEndingBalance',
      isEditable: !stepCompletionStatusValue,
      isNumber: true,
      width: 100
    },
    {
      headerName: t('Difference'),
      field: 'difference',
      isNumber: true,
      width: 100
    }
  ];
}

export function getDeferredKeyColumn(t: TFunction): ColumnWithField[] {
  return [{ field: 'name', headerName: t('Adjustment Name') }];
}

export function getPermanentKeyColumns(t: TFunction): ColumnWithField[] {
  return [
    {
      field: 'accountNumber',
      headerName: t('Trial Balance'),
      filterable: true,
      sortable: true,
      placeholder: t('Trial Balance'),
      width: '18%'
    },
    {
      field: 'name',
      headerName: t('Account Description'),
      divider: true,
      filterable: true,
      sortable: true,
      placeholder: t('Account Description'),
      isNewRowEditable: true,
      width: '18%'
    }
  ];
}

export function getPermanentColumns(
  t: TFunction,
  prov4011TotalRowFix: boolean,
  isEditable: Column['isEditable'] = true
): ColumnWithField[] {
  return [
    {
      field: 'amount',
      headerName: t('Amount'),
      isEditable,
      isNumber: true
    },
    {
      field: 'disallowedPct',
      headerName: t('Percentage Disallowed'),
      isEditable,
      isPercentage: true,
      renderCell: renderRTPPercentageCell,
      prov4011TotalRowFix
    },
    {
      field: 'm1Adjustment',
      headerName: t('M-1 Adjustment'),
      isNumber: true
    }
  ];
}

export function getTemporaryIncomeStatementColumns(
  t: TFunction,
  isEditable: Column['isEditable'] = true
): ColumnWithField[] {
  return [
    {
      field: 'accountNumber',
      headerName: t('Trial Balance'),
      isEditable
    },
    {
      field: 'name',
      headerName: t('Account Description'),
      isEditable
    },
    {
      field: 'bookBalance',
      headerName: t('Book Balance'),
      isEditable,
      isNumber: true
    },
    {
      field: 'taxBalance',
      headerName: t('Tax Balance'),
      isEditable,
      isNumber: true
    },
    {
      field: 'm1Adjustment',
      headerName: t('M-1 Adjustment'),
      isNumber: true
    }
  ];
}

export function getPtbiColumns(t: TFunction) {
  return [
    {
      field: 'name',
      filterable: true,
      sortable: true,
      divider: true,
      placeholder: t('entity-name'),
      isNewRowEditable: true,
      width: '15%'
    },
    {
      field: 'ptbi',
      headerName: t('Pre-Tax Book Income / (Loss)'),
      width: '20%',
      isEditable: true,
      isNumber: true
    },
    {
      field: 'adjustments',
      headerName: t('Adjustments'),
      width: '20%',
      isEditable: true,
      isNumber: true
    },
    {
      field: 'adjustedPtbi',
      headerName: t('Adjusted Pre-Tax Book Income / (Loss)'),
      width: '20%',
      getValue: calculateAdjustedPtbi,
      isNumber: true
    }
  ];
}

export function getStateApportionmentKeyColumns(t: TFunction): ColumnWithField[] {
  return [
    {
      field: 'state',
      headerName: t('State'),
      divider: true,
      filterable: true,
      sortable: true,
      placeholder: t('State'),
      width: '18%'
    }
  ];
}

export function getStateApportionmentColumns(t: TFunction): ColumnWithField[] {
  return [
    {
      field: 'apportionment',
      headerName: t('Apportionment'),
      isPercentage: true
    }
  ];
}

export function getStateApportionmentColumnsFlagged(t: TFunction): ColumnWithField[] {
  return [
    {
      field: 'beginning',
      headerName: t('Beginning'),
      isPercentage: true
    },
    {
      field: 'current',
      headerName: t('Current'),
      isPercentage: true
    },
    {
      field: 'end',
      headerName: t('End'),
      isPercentage: true
    }
  ];
}

export function getStateModificationColumns(t: TFunction) {
  return [
    {
      field: 'name',
      divider: true,
      filterable: true,
      sortable: true,
      placeholder: t('Entity Name'),
      isNewRowEditable: true,
      width: '15%'
    },
    {
      field: 'isPermanent',
      headerName: t('Modification Type'),
      getValue: ({ isPermanent }: any) => t(isPermanent ? 'permanent' : 'temporary')
    },
    {
      field: 'federalAmount',
      headerName: t('Federal Amount'),
      isEditable: true,
      isNumber: true
    },
    {
      field: 'amount',
      headerName: t('State Amount'),
      isEditable: true,
      isNumber: true
    },
    {
      field: 'modification',
      headerName: t('Modification'),
      isNumber: true
    }
  ];
}

export function getFederalTaxEffectedColumns(t: TFunction) {
  return [
    {
      field: 'name',
      divider: true,
      filterable: true,
      sortable: true,
      placeholder: t('Entity Name'),
      width: '25%'
    },
    {
      field: 'amount',
      headerName: t('Amount'),
      isEditable: true,
      isNumber: true
    }
  ];
}

export function getFederalTaxEffectedColumnsForUpload(t: TFunction) {
  return [
    {
      field: 'name',
      headerName: t('Account Description')
    },
    {
      field: 'amount',
      headerName: t('Amount')
    }
  ];
}

export function getDiscreteColumns(t: TFunction, isEditable: Column['isEditable'] = true) {
  return [
    {
      field: 'name',
      headerName: t('Adjustment Name'),
      isNewRowEditable: true
    },
    {
      field: 'amount',
      headerName: t('Amount'),
      isEditable,
      isNumber: true
    }
  ];
}

export function getEntitySubJurisdictionData({ entity, jurisdictions, t }: any) {
  const subJurisdictionById = findStateMap(jurisdictions, entity?.jurisdictionId);
  const subJurisdictionList: SubJurisdiction[] = [];

  for (const id of entity?.subJurisdictionIds || []) {
    const entitySubjurisdiction = subJurisdictionById.get(id);
    if (entitySubjurisdiction) {
      subJurisdictionList.push({
        ...(entitySubjurisdiction ?? {}),
        name: formatSubJurisdictionForDisplay(t, subJurisdictionById.get(id))?.name
      });
    }
  }

  return { subJurisdictionById, entitySubJurisdictions: subJurisdictionList };
}

export function getStateTaxEffectedColumns(t: TFunction) {
  return [
    {
      field: 'name',
      filterable: true,
      sortable: true,
      placeholder: t('entity-name'),
      isNewRowEditable: true,
      width: '18%'
    },
    {
      field: 'state',
      placeholder: t('state'),
      isEditable: true,
      divider: true,
      filterable: true,
      sortable: true,
      width: '18%'
    },
    {
      field: 'amount',
      headerName: t('Amount'),
      isEditable: true,
      isNumber: true,
      width: '18%'
    }
  ];
}

export function getFederalCreditNameColumn(t: TFunction): ColumnWithField[] {
  return [
    {
      field: 'creditName',
      headerName: t('Credit Name'),
      divider: true,
      filterable: true,
      sortable: true
    }
  ];
}

export type EntityNumberRouteMatch = {
  [key: string]: string;
  entityNumber: string;
};

export type RowsForCreateSourceObject = Record<
  string,
  RowForGetTableDataFromFinancialData | RowForGetTableDataFromFinancialData[]
>;

export function getRowValuesForRtp({
  rowName,
  accountNumber,
  childAdjustments, // rtp data
  creditName,
  jurisdictionId,
  level,
  step,
  rowId,
  isStatic // some rtp adjustments have no rowId to compare with these are static adjustments
}: {
  rowName: string;
  accountNumber: string | null;
  childAdjustments: FinancialInfo[];
  creditName?: string;
  jurisdictionId?: string;
  level?: Level;
  step?: Step;
  rowId?: string;
  isStatic?: boolean;
}) {
  const rowData: any = {
    name: rowName,
    accountNumber,
    creditName,
    level,
    step,
    rowId
  };
  childAdjustments
    .filter((item) => {
      if (isStatic) {
        return (
          item.rowName === rowName &&
          (item.creditName === creditName || !creditName) &&
          (item.jurisdictionId === jurisdictionId || !jurisdictionId)
        );
      }

      return item?.sourceRowId
        ? item.sourceRowId === rowId
        : item.rowId === rowId && // to handle case where child adj do not exist yet
            (item.creditName === creditName || !creditName) &&
            (item.jurisdictionId === jurisdictionId || !jurisdictionId);
    })
    .forEach((item) => {
      rowData.accountId = item.accountId;
      rowData.accountNumber = item.accountNumber ?? accountNumber;
      rowData[item.columnName] = item.value;
      rowData.jurisdictionId = item.jurisdictionId;
      rowData.creditName = item.creditName;
      rowData.step = item.step;
      rowData.level = item.level;
      rowData.rowId = item.rowId;
      rowData.sourceRowId = item.sourceRowId;
    });

  return rowData;
}

const composeStepBasedRowData = (row: any, filteredFinancialData: FinancialInfo[]) => {
  const composedData: RowForGetTableDataFromFinancialData = { name: row.name, step: row.name };

  filteredFinancialData.forEach((item) => {
    composedData.step = item.step;
    composedData.level = item.level;
    composedData.accountId = item.accountId;
    composedData.accountNumber = item.accountNumber;
    composedData.rowId = item.rowId;
    composedData.sourceRowId = item?.sourceRowId ?? undefined;
    composedData[item.columnName] = item.value;
  });

  return composedData;
};

export function getStepBasedRowValues(row: any, financialData: FinancialInfo[], filterAdjustmentsByRowId: boolean) {
  const filteredFinancialData = financialData.filter((item) =>
    filterAdjustmentsByRowId ? item.rowId === row.rowId : item.rowName === row.name
  );

  return composeStepBasedRowData(row, filteredFinancialData);
}

export function getTableDataForRtp(
  sourceAdjustments: RowForGetTableDataFromFinancialData[],
  // populates rtp table
  childAdjustments: FinancialInfo[]
): GetTableDataFromFinancialDataReturnType[] {
  return sourceAdjustments
    .map((row) =>
      getRowValuesForRtp({
        rowName: row.name,
        accountNumber: row.accountNumber,
        childAdjustments,
        creditName: row.creditName,
        level: row.level,
        step: row.step,
        jurisdictionId: row.jurisdictionId,
        rowId: row.rowId, // source adjustment rowId if rtp/deferred does not exist yet
        isStatic: row?.isStatic ?? false
      })
    )
    .filter((row, index) => {
      const doesRowContainData = Object.keys(row).length > 1;
      if (doesRowContainData || !sourceAdjustments[index].hideIfEmpty) {
        // TODO: remove `!rows[index].hideIfEmpty` if no tabs are using 'hideIfEmpty'
        return true;
      }

      return false;
    })
    .map((row) => ({ ...addNullAccountIdToRowsWhichDoNotHaveAccountId(row) }));
}

export function getTableDataFromFinancialData(
  rows: RowForGetTableDataFromFinancialData[],
  financialData: FinancialInfo[]
): GetTableDataFromFinancialDataReturnType[] {
  return rows
    .map((row) =>
      getRowValues({
        rowName: row.name,
        financialData,
        creditName: row.creditName,
        level: row.level,
        step: row.step,
        jurisdictionId: row.jurisdictionId
      })
    )
    .filter((row, index) => {
      const doesRowContainData = Object.keys(row).length > 1;
      if (doesRowContainData || !rows[index].hideIfEmpty) {
        // TODO: remove `!rows[index].hideIfEmpty` if no tabs are using 'hideIfEmpty'
        return true;
      }

      return false;
    })
    .map((row) => ({ ...addNullAccountIdToRowsWhichDoNotHaveAccountId(row) }));
}

export function getRowValues({
  rowName,
  financialData,
  creditName,
  jurisdictionId,
  level,
  step
}: {
  rowName: string;
  financialData: FinancialInfo[];
  creditName?: string;
  jurisdictionId?: string;
  level?: Level;
  step?: Step;
}) {
  const rowData: RowForGetTableDataFromFinancialData = { name: rowName, creditName, level, step };

  financialData
    .filter(
      (item) =>
        item.rowName === rowName &&
        (item.creditName === creditName || !creditName) &&
        (item.jurisdictionId === jurisdictionId || !jurisdictionId)
    )
    .forEach((item) => {
      rowData.accountId = item.accountId;
      rowData.accountNumber = item.accountNumber;
      rowData[item.columnName] = item.value;
      rowData.jurisdictionId = item.jurisdictionId;
      rowData.creditName = item.creditName;
      rowData.step = item.step;
      rowData.level = item.level;
      rowData.rowId = item.rowId;
    });

  return rowData;
}

// eslint-disable-next-line max-params
export function getTableDataByRowIdFinancialData(
  level: Level,
  step: Step,
  financialData: FinancialInfo[],
  filterAdjustmentsByRowId: boolean,
  tabsData?: any
): GetTableDataFromFinancialDataReturnType[] {
  if (!filterAdjustmentsByRowId) {
    const rowNames = getRowNamesFromLevelSteps(tabsData, level, [step])[step];
    return getTableDataFromFinancialData(rowNames, financialData);
  }

  const levelAndStep = `${level}.${step}`;
  const rows: any = (tabsData ? tabsData[levelAndStep] || [] : financialData || []).filter(
    (cell: any, index: any, rows: any[]) => index === rows.findIndex((row) => row.rowId === cell.rowId)
  );

  return rows
    .map((row: any) => {
      const rowData: any = {
        name: row.rowName,
        rowId: row.rowId
      };

      financialData
        .filter((item) => item.rowId === row.rowId)
        .forEach((item) => {
          rowData.accountId = item.accountId;
          rowData.accountNumber = item.accountNumber;
          rowData[item.columnName] = item.value;
          rowData.jurisdictionId = item.jurisdictionId;
          rowData.creditName = item.creditName;
          rowData.step = item.step;
          rowData.level = item.level;
          rowData.rowId = item.rowId;
          rowData.accountId = item.accountId ?? NULL_UUID;
          rowData.sourceRowId = item.sourceRowId;
        });
      return rowData;
    })
    .filter((row: any, index: any) => {
      const doesRowContainData = Object.keys(row).length > 1;
      if (doesRowContainData || !rows[index].hideIfEmpty) {
        // TODO: remove `!rows[index].hideIfEmpty` if no tabs are using 'hideIfEmpty'
        return true;
      }

      return false;
    });
}

export function getStepBasedTableDataFromFinancialData(
  rows: RowForGetTableDataFromFinancialData[],
  financialData: FinancialInfo[],
  filterAdjustmentsByRowId: boolean
): GetTableDataFromFinancialDataReturnType[] {
  return rows
    .map((row) => getStepBasedRowValues(row, financialData, filterAdjustmentsByRowId))
    .filter((row, index) => {
      const doesRowContainData = Object.keys(row).length > 1;
      if (doesRowContainData || !rows[index].hideIfEmpty) {
        return true;
      }

      return false;
    })
    .map((row) => ({ ...addNullAccountIdToRowsWhichDoNotHaveAccountId(row) }));
}

export function createSourceObject(
  childAdjustments: FinancialInfo[],
  sourceAdjustments: any,
  filterAdjustmentsByRowId = false
) {
  const source: RowsForCreateSourceObject = {};
  // eslint-disable-next-line guard-for-in
  for (const key in sourceAdjustments) {
    const rowsMappingKey = sourceAdjustments[key];

    if (Array.isArray(rowsMappingKey)) {
      source[key] = filterAdjustmentsByRowId
        ? getTableDataForRtp(rowsMappingKey, childAdjustments)
        : getTableDataFromFinancialData(rowsMappingKey, childAdjustments);
    } else {
      source[key] = filterAdjustmentsByRowId
        ? getTableDataForRtp([rowsMappingKey], childAdjustments)[0] ?? {}
        : getTableDataFromFinancialData([rowsMappingKey], childAdjustments)[0] ?? {};
    }
  }

  return source;
}

// eslint-disable-next-line max-params
export function getRowNamesFromLevelSteps(
  tabsData: FinancialInfoTabsData,
  level: Level,
  steps: Step[],
  withSection = false,
  withJurisdictionId = false
): Record<Step, [{ name: FinancialInfo['rowName']; level: FinancialInfo['level']; step: FinancialInfo['step'] }]> {
  return Object.assign(
    {},
    ...steps.map((step) => {
      const levelAndStep = `${level}.${step}`;
      const cellData =
        tabsData[levelAndStep]?.map((cell) => ({
          rowName: cell.rowName,
          creditName: cell.creditName,
          jurisdictionId: cell.jurisdictionId,
          level: cell.level,
          step: cell.step
        })) ?? [];
      const uniqueRowNames: any[] = [];
      for (const cell of cellData) {
        if (
          !uniqueRowNames.some(
            (row: any) =>
              row.rowName === cell.rowName &&
              (row.creditName === cell.creditName || !cell.creditName) &&
              (!withJurisdictionId || cell.jurisdictionId === row.jurisdictionId)
          )
        ) {
          uniqueRowNames.push(cell);
        }
      }

      const rowNamesForTable = uniqueRowNames.map((row) => ({
        name: row.rowName,
        creditName: withSection ? levelAndStep : row.creditName,
        jurisdictionId: withJurisdictionId ? row.jurisdictionId : undefined,
        level: row.level,
        step: row.step
      }));

      return {
        [step]: rowNamesForTable
      };
    })
  );
}

// eslint-disable-next-line max-params
export function getRowsFromLevelSteps(
  tabsData: FinancialInfoTabsData,
  level: Level,
  steps: Step[],
  withSection = false,
  withJurisdictionId = false
): Record<Step, [{ name: FinancialInfo['rowName']; level: FinancialInfo['level']; step: FinancialInfo['step'] }]> {
  return Object.assign(
    {},
    ...steps.map((step) => {
      const levelAndStep = `${level}.${step}`;
      const cellData =
        tabsData[levelAndStep]?.map((cell) => ({
          rowName: cell.rowName,
          creditName: cell.creditName,
          jurisdictionId: cell.jurisdictionId,
          level: cell.level,
          step: cell.step,
          rowId: cell.rowId,
          sourceRowId: cell.sourceRowId,
          accountNumber: cell.accountNumber
        })) ?? [];
      const uniqueRows: any[] = [];
      for (const cell of cellData) {
        if (
          !uniqueRows.some(
            (row: any) =>
              row.rowId === cell.rowId &&
              (row.creditName === cell.creditName || !cell.creditName) &&
              (!withJurisdictionId || cell.jurisdictionId === row.jurisdictionId)
          )
        ) {
          uniqueRows.push(cell);
        }
      }

      const rowsForTable = uniqueRows.map((row) => ({
        name: row.rowName,
        creditName: withSection ? levelAndStep : row.creditName,
        jurisdictionId: withJurisdictionId ? row.jurisdictionId : undefined,
        level: row.level,
        step: row.step,
        rowId: row.rowId,
        sourceRowId: row.sourceRowId,
        accountNumber: row.accountNumber
      }));

      return {
        [step]: rowsForTable
      };
    })
  );
}

export function getRowNamesAndSectionsFromLevelSteps(
  tabsData: FinancialInfoTabsData,
  level: Level,
  steps: Step[]
): Record<Step, [{ name: FinancialInfo['rowName']; level: FinancialInfo['level']; step: FinancialInfo['step'] }]> {
  return getRowNamesFromLevelSteps(tabsData, level, steps, true);
}

export function getRowNamesAndJurisdictionIdFromLevelSteps(
  tabsData: FinancialInfoTabsData,
  level: Level,
  steps: Step[]
): Record<Step, [{ name: FinancialInfo['rowName'] }]> {
  return getRowNamesFromLevelSteps(tabsData, level, steps, false, true);
}

function convertNewRowDataToCreateFinancialData({
  columns,
  jurisdictionId,
  level,
  newRow,
  step
}: {
  columns: ColumnWithField[];
  jurisdictionId?: Jurisdiction['jurisdictionId'];
  level: Level;
  newRow: Row;
  step: Step;
}) {
  const allEditableColumns = columns.filter((column) => column.isEditable !== undefined);
  allEditableColumns.push({ field: 'note' });

  const dataForFinancial = allEditableColumns.map((column) => ({
    accountId: NULL_UUID,
    columnName: column.field,
    jurisdictionId,
    level,
    rowName: newRow.name!,
    step,
    value: newRow[column.field],
    creditName: newRow.creditName,
    sourceRowId: newRow?.rowId ?? undefined
  }));

  const areAllValuesUndefined = dataForFinancial
    .filter((column) => column.columnName !== 'state')
    .every((column) => column.value === undefined);

  if (dataForFinancial.length > 0 && areAllValuesUndefined) {
    const firstNonStateColumn = dataForFinancial.find((column) => column.columnName !== 'state') ?? dataForFinancial[0];
    firstNonStateColumn.value = dataForFinancial[0].columnName === 'note' ? '' : 0;
  }

  return dataForFinancial;
}

// eslint-disable-next-line max-params
export function doesRowNameAlreadyExistInStep(
  rowName: FinancialInfo['rowName'],
  step: Step,
  financialInfo: FinancialInfo[],
  jurisdictionId?: FinancialInfo['jurisdictionId'],
  creditName?: FinancialInfo['creditName'],
  sourceRowId?: string | undefined,
  filterAdjustmentsByRowId?: boolean
) {
  if (step === 'temporary.balanceSheet' || step === 'temporary.incomeStatement') {
    return financialInfo.some(
      (item) =>
        (item.step === 'temporary.balanceSheet' || 'temporary.incomeStatement') &&
        rowName.toLowerCase() === item.rowName.toLowerCase()
    );
  }

  // sourceRowId is for state modification adjustments that tie in with a federal adjustment
  if (
    step === 'modifications.permanent' ||
    (step === 'modifications.temporary' && sourceRowId && filterAdjustmentsByRowId)
  ) {
    return financialInfo.some(
      (item) =>
        item.step === step &&
        sourceRowId === item.sourceRowId &&
        (!jurisdictionId || jurisdictionId === item.jurisdictionId) &&
        (!creditName || creditName === item.creditName)
    );
  }

  return financialInfo.some(
    (item) =>
      item.step === step &&
      rowName.toLowerCase() === item.rowName.toLowerCase() &&
      (!jurisdictionId || jurisdictionId === item.jurisdictionId) &&
      (!creditName || creditName === item.creditName)
  );
}

export function isCreditNameInFinancials(financialInfo: FinancialInfo[], creditName?: FinancialInfo['creditName']) {
  return financialInfo.some((item) => creditName?.toLowerCase() === item.creditName?.toLowerCase());
}

export function handleNewRowForEntityDetails({
  columns,
  dispatch,
  entityId,
  financialInfo,
  hasNewRow,
  jurisdictionId,
  level,
  rows,
  setHasNewRow,
  setRows,
  step,
  creditName,
  t,
  showReservedWordsError,
  filterAdjustmentsByRowId = false
}: {
  columns: ColumnWithField[];
  dispatch: Dispatch<any>;
  entityId?: Entity['entityId'];
  financialInfo: FinancialInfo[];
  hasNewRow?: boolean;
  jurisdictionId?: Jurisdiction['jurisdictionId'];
  level: Level;
  rows: Row[];
  setHasNewRow: React.Dispatch<SetStateAction<boolean>>;
  setRows: React.Dispatch<SetStateAction<Row[]>>;
  step: Step;
  creditName?: string;
  t: TFunction;
  showReservedWordsError?: boolean;
  filterAdjustmentsByRowId?: boolean;
}) {
  if (hasNewRow) {
    const newRow = rows.find((row) => row.isNew) ?? {};
    const isStateRequired = step?.startsWith('modifications') || (level === 'state' && step === 'tax-effected');
    const hasStateSelected = isStateRequired ? 'state' in newRow : true;
    if ('name' in newRow && newRow.name?.trim().length && entityId && hasStateSelected) {
      // don't allow save modification  or state tax-effected adjustment without any states.
      const nameIsReserved = showReservedWordsError && reservedNames.includes(newRow.name.toLowerCase());
      if (
        doesRowNameAlreadyExistInStep(
          newRow.name,
          step,
          financialInfo,
          jurisdictionId,
          newRow.creditName,
          newRow?.sourceRowId ?? undefined,
          filterAdjustmentsByRowId
        ) ||
        nameIsReserved
      ) {
        const nameColumn = columns.find((column) => column.field === 'name');
        const headerNameOfNameColumn = nameColumn?.headerName ?? nameColumn?.placeholder ?? '';
        dispatch(
          financialDataOnUpdatedFailedCells({
            rowNames: [newRow.name],
            columnNames: ['name'],
            creditName,
            didFail: true,
            failedReasons: nameIsReserved
              ? [
                  t(
                    `'${newRow.name}' is one of a list of reserved words in the system and must not be used for naming. Please choose a different name.`
                  )
                ]
              : [t(`${headerNameOfNameColumn} already exists`)]
          })
        );
      } else {
        dispatch(
          createFinancialData(
            convertNewRowDataToCreateFinancialData({ columns, jurisdictionId, level, step, newRow }),
            entityId,
            {
              onDone: () => {
                setRows(rows.filter((row) => !row.isNew));
                setHasNewRow(false);
              }
            }
          )
        );
      }
    } else {
      let missingFields: any[] = [];
      if (!('name' in newRow)) {
        missingFields.push('name');
      }

      if (isStateRequired && !('state' in newRow)) {
        missingFields.push('state');
      }

      missingFields = missingFields.map((field) => columns?.find((col) => col.field === field)?.headerName ?? '');

      dispatch(
        financialDataOnUpdatedFailedCells({
          rowNames: [''],
          columnNames: missingFields,
          creditName,
          didFail: true,
          failedReasons: [t(`Missing fields: ${missingFields.join(', ')}`)]
        })
      );
    }
  } else {
    dispatch(
      financialDataOnUpdatedFailedCells({
        rowNames: [''],
        columnNames: ['name'],
        creditName,
        didFail: false
      })
    );

    setHasNewRow(true);
    setRows([...rows, { isNew: true, creditName }]);
  }
}

export const handleOnCellOrCommentBlurForEntityDetails = ({
  column,
  dispatch,
  entityId,
  jurisdictionId,
  level,
  row,
  rows,
  setRows,
  step,
  value,
  disablePriorYearError,
  filterAdjustmentsByRowId = false
}: {
  column: Column;
  dispatch: Dispatch<any>;
  entityId?: Entity['entityId'];
  jurisdictionId?: Jurisdiction['jurisdictionId'];
  level: Level;
  row: Row;
  rows: Row[];
  setRows?: React.Dispatch<SetStateAction<Row[]>>;
  step: Step;
  value: FinancialInfoValue;
  disablePriorYearError?: boolean;
  filterAdjustmentsByRowId?: boolean;
}) => {
  if (row.isNew) {
    if (column.field === 'note') {
      const newRow = rows.find((item) => item.isNew);
      if (newRow) {
        const idx = rows.indexOf(newRow);
        setRows?.([...rows.slice(0, idx), { ...row, note: value as string }, ...rows.slice(idx + 1)]);
      }
    }

    return;
  }

  dispatch(
    updateFinancialData(
      {
        accountId: row.accountId,
        accountNumber: row.accountNumber,
        // @ts-expect-error TODO - we need to figure out if row name can really be undefined
        rowName: row.name,
        // @ts-expect-error TODO - we need to figure out if column field can really be undefined
        columnName: column.field,
        jurisdictionId,
        creditName: row.creditName,
        rowId: row.rowId,
        level,
        step,
        value,
        sourceRowId: row?.sourceRowId ?? null
      },
      entityId,
      disablePriorYearError,
      filterAdjustmentsByRowId
    )
  );
};

const isFederalLinkedFinancial = (fin: FinancialInfo, levelAndStep: string, linkedRowName: string) =>
  fin.level === LEVELS.FEDERAL &&
  (fin.step === 'deferred' || fin.step === 'rtp') &&
  fin.creditName === levelAndStep &&
  fin.rowName === linkedRowName;

function getFederalRowsToCascadeDelete({
  financialInfo,
  step,
  row
}: {
  financialInfo: FinancialInfo[];
  step: Step;
  row: Row;
}) {
  const levelAndStep = `${LEVELS.FEDERAL}.${step}`;
  const linkedRowName = row.name!;

  const linkedFinancials = financialInfo.filter((fin) => isFederalLinkedFinancial(fin, levelAndStep, linkedRowName));
  return linkedFinancials;
}

const isStateDeferredLinkedFinancial = (fin: FinancialInfo, deferredRowNames: Set<string>, levelAndStep: string) =>
  fin.level === LEVELS.STATE &&
  fin.step === 'deferred' &&
  deferredRowNames.has(fin.rowName) &&
  !fin.jurisdictionId &&
  fin.creditName === levelAndStep;

const isStateRTPLinkedFinancial = (
  fin: FinancialInfo,
  linkedRowName: string,
  statesAssignedToRow: Array<SubJurisdiction['id']>,
  levelAndStep: string
) =>
  fin.level === LEVELS.STATE &&
  fin.step === 'rtp' &&
  fin.rowName === linkedRowName &&
  fin.jurisdictionId &&
  statesAssignedToRow.includes(fin.jurisdictionId) &&
  fin.creditName === levelAndStep;

function getStateRowsToCascadeDelete({
  financialInfo,
  step,
  row,
  states
}: {
  financialInfo: FinancialInfo[];
  step: Step;
  row: Row;
  states: SubJurisdiction[];
}) {
  const levelAndStep = `${LEVELS.STATE}.${step}`;
  const subJurisdictionById = turnToObjById(states);
  // @ts-expect-error TODO - we need to figure out if row name can really be undefined
  const linkedRowName: string = row.name;

  const statesAssignedToRow: Array<SubJurisdiction['id']> = row.state;

  const deferredRowNames = new Set(
    statesAssignedToRow.map((jurisdictionId) => `${subJurisdictionById[jurisdictionId].name} - ${linkedRowName}`)
  );

  const linkedFinancials = financialInfo.filter(
    (item) =>
      isStateDeferredLinkedFinancial(item, deferredRowNames, levelAndStep) ||
      isStateRTPLinkedFinancial(item, linkedRowName, statesAssignedToRow, levelAndStep)
  );
  return linkedFinancials;
}

const getUniqueRowsFromFinancials = (financials: FinancialInfo[]) => {
  const uniqueRowsMap: Record<string, FinancialRowForDeletion> = {};
  for (const fin of financials) {
    uniqueRowsMap[fin.rowId] = {
      rowId: fin.rowId,
      rowName: fin.rowName,
      level: fin.level,
      step: fin.step
    };
  }

  return Object.values(uniqueRowsMap);
};

export function handleOnRowDeleteForEntityDetails({
  dispatch,
  entityId,
  hasNewRow,
  financialInfo,
  level,
  row,
  rows,
  setHasNewRow,
  setRows,
  step,
  subjurisdictions
}: {
  dispatch: Dispatch<any>;
  entityId?: Entity['entityId'];
  financialInfo: FinancialInfo[];
  hasNewRow?: boolean;
  level: Level;
  row: Row;
  rows: Row[];
  setHasNewRow: React.Dispatch<SetStateAction<boolean>>;
  setRows: React.Dispatch<SetStateAction<Row[]>>;
  subjurisdictions?: SubJurisdiction[];
  step: Step;
}) {
  const isDeletingNewRow = hasNewRow && row.isNew;
  if (isDeletingNewRow) {
    setRows(rows.filter((row) => !row.isNew));
    setHasNewRow(false);
  } else if (entityId) {
    let linkedFinancials: FinancialInfo[] = [];
    if (
      level === LEVELS.FEDERAL &&
      ['temporary.balanceSheet', 'temporary.incomeStatement', 'permanent', 'tax-effected'].includes(step)
    ) {
      linkedFinancials = getFederalRowsToCascadeDelete({ financialInfo, step, row });
    } else if (
      level === LEVELS.STATE &&
      ['modifications.temporary', 'modifications.permanent', 'tax-effected'].includes(step) &&
      Array.isArray(subjurisdictions)
    ) {
      linkedFinancials = getStateRowsToCascadeDelete({ financialInfo, step, row, states: subjurisdictions });
    }

    const linkedRowsToDelete = getUniqueRowsFromFinancials(linkedFinancials);

    const mergedRowsToDelete = [
      {
        rowId: row.rowId,
        rowName: row.name!,
        level,
        step
      },
      ...linkedRowsToDelete
    ];

    dispatch(deleteFinancialData(entityId, mergedRowsToDelete));
  }
}

export function handleDeleteStateRTPTaxProvisionCol({
  dispatch,
  entityId
}: {
  dispatch: Dispatch<any>;
  entityId: Entity['entityId'];
}) {
  if (entityId) {
    dispatch(deleteStateRTPTaxProvisionCol(entityId));
  }
}

export function handleEditRowForEntityDetails({
  calculateFunc,
  column,
  row,
  rows,
  setRows,
  value
}: {
  calculateFunc?: (...args: any) => any;
  column: Column;
  row: Row;
  rows: Row[];
  setRows: React.Dispatch<SetStateAction<Row[]>>;
  value: FinancialInfoValue;
}) {
  const idx = rows.indexOf(row);

  if (idx >= 0 && Boolean(column.field)) {
    const field = column.field!;
    setRows([
      ...rows.slice(0, idx),
      calculateFunc ? calculateFunc({ ...row, [field]: value }) : { ...row, [field]: value },
      ...rows.slice(idx + 1)
    ]);
  }
}

export function mergeTemporaryBalanceSheetRowData({
  balanceSheetRows = [],
  rtpRows = [],
  filterAdjustmentsByRowId
}: Record<string, BalanceSheetAccountRows[]>) {
  const result = [];

  for (const balanceSheetRow of balanceSheetRows) {
    const rtpRow =
      rtpRows.find((row) =>
        filterAdjustmentsByRowId ? row.sourceRowId === balanceSheetRow.rowId : row.name === balanceSheetRow.name
      ) ?? {};

    const mergedRow = {
      ...rtpRow,
      ...balanceSheetRow
    };
    result.push(mergedRow);
  }

  return result;
}

export const renderRTPPercentageCell = (
  row: Row,
  value: number,
  column: Column,
  failedCells: FailedCells,
  onCellChange: TableProps['onCellChange'],
  onCellOrCommentBlur: TableProps['onCellOrCommentBlur']
  // eslint-disable-next-line max-params
) => {
  const props = {
    row,
    value,
    failedCells,
    column: { ...column, isNumber: false, isPercentage: true },
    renderOpts: {
      numberOfDecimals: 4
    },
    onCellChange,
    onCellOrCommentBlur
  };
  const isEditableLogic = column?.prov4011TotalRowFix
    ? column.isEditable && !Object.hasOwn(row, 'total')
    : column.isEditable;
  // For Non Completed Values
  if (isEditableLogic) {
    return <EditableNumericCell {...props} />;
  }

  // For Completed Values
  if (
    column.field === 'taxProvision' ||
    column.field === 'taxReturn' ||
    column.field === 'disallowedPct' ||
    column.field === 'variance'
  ) {
    return renderValue(props?.value, props?.column || {}, props?.renderOpts);
  }

  return value;
};

export const getFederalPtbiColumns = (t: TFunction) => [
  {
    field: 'ptbiAmount',
    headerName: t('Pre-Tax Book Income Amount'),
    filterable: true,
    sortable: true,
    width: '18%',
    isNumber: true
  },
  {
    field: 'adjustmentsAmount',
    headerName: t('Adjustments Amount'),
    filterable: true,
    sortable: true,
    width: '18%',
    isNumber: true
  }
];

export const getFederalRtpColumns = (t: TFunction) => {
  const result: ColumnWithField[] = [
    {
      field: 'name',
      headerName: t('Name'),
      isNumber: false
    },
    {
      field: 'taxProvision',
      headerName: t('Tax Provision'),
      isNumber: true
    },
    {
      field: 'taxProvision',
      headerName: t('Tax Return'),
      isNumber: true
    }
  ];

  return result;
};

export const getStateTaxEffectedColumnsForUpload = (t: TFunction) => [
  {
    field: 'name',
    headerName: t('Adjustment Name')
  },
  {
    field: 'state',
    headerName: t('State')
  },
  {
    field: 'amount',
    headerName: t('Amount'),
    isNumber: true
  }
];

export const getStateModificationColumnsForUpload = (t: TFunction) => [
  {
    field: 'modificationType',
    headerName: t('Modification Type')
  },
  {
    field: 'name',
    headerName: t('Account Description')
  },
  {
    field: 'amount',
    headerName: t('Amount'),
    isNumber: true
  },
  {
    field: 'state',
    headerName: t('State')
  }
];

export const getStateRtpColumns = (t: TFunction) => {
  const result: ColumnWithField[] = [
    {
      field: 'name',
      headerName: t('Name'),
      isNumber: false
    },
    {
      field: 'taxProvision',
      headerName: t('Tax Provision'),
      isNumber: true
    },
    {
      field: 'taxProvision',
      headerName: t('Tax Return'),
      isNumber: true
    }
  ];

  return result;
};

export const getStateCreditNameColumn = (t: TFunction) => {
  const result: ColumnWithField[] = [
    {
      field: 'creditName',
      headerName: t('Credit Name')
    }
  ];

  return result;
};

export const getStateDeferredColumns = (t: TFunction) => {
  const result: ColumnWithField[] = [
    {
      field: 'state',
      headerName: t('State')
    },
    {
      field: 'name',
      headerName: t('Adjustment Name'),
      isNumber: false
    },
    {
      field: 'beginningBalance',
      headerName: t('Beginning Balance'),
      isNumber: true
    },
    {
      field: 'deferredOnlyAdjustment',
      headerName: t('Deferred Only Adjustment'),
      isNumber: true
    },
    {
      field: 'balanceSheetOnlyAdjustment',
      headerName: t('Balance Sheet Only Adjustment'),
      isNumber: true
    }
  ];

  return result;
};

export function findStepsWithStatus(
  completionStatuses: any,
  shouldBeCompleted: boolean,
  numOfJurs = 1,
  isFederal = true
) {
  const levelStatuses = completionStatuses[isFederal ? 'federal' : 'state'] ?? {};
  const stepsWithStatus = Object.keys(levelStatuses)
    .map((step) => step as Step)
    .filter((step) => {
      const stepStatusesPerJurId = levelStatuses[step] ?? {};
      const completedJurs = Object.values(stepStatusesPerJurId).filter(Boolean);
      const stepNumOfJurs = ['rtp', 'nol', 'credits', 'accounts'].includes(step) ? numOfJurs : 1;
      const isFullyCompleted = completedJurs.length === stepNumOfJurs;
      if (completedJurs.length === 0 && stepNumOfJurs > 1) {
        return false;
      }

      return shouldBeCompleted === isFullyCompleted;
    });

  return stepsWithStatus;
}

interface GetStepsWithStatusArgs {
  entityCompletionStatus: {
    completionStatus: CompletionStatus;
    numOfStates: number;
  };
  federalSteps: readonly Step[];
  isUserReviewer?: boolean;
  stateSteps: readonly Step[];
}

// eslint-disable-next-line complexity
export const getStepsWithStatus = ({
  entityCompletionStatus,
  federalSteps,
  isUserReviewer,
  stateSteps
}: GetStepsWithStatusArgs) => {
  const federalStepsCopy = [...federalSteps];
  const stateStepsCopy = [...stateSteps];
  const completedFederalSteps = findStepsWithStatus(entityCompletionStatus?.completionStatus ?? {}, true, 1, true);
  const completedStateSteps = findStepsWithStatus(
    entityCompletionStatus?.completionStatus ?? {},
    true,
    entityCompletionStatus.numOfStates,
    false
  );

  const inProgressFederalSteps = findStepsWithStatus(entityCompletionStatus?.completionStatus ?? {}, false, 1, true);
  const inProgressStateSteps = findStepsWithStatus(
    entityCompletionStatus?.completionStatus ?? {},
    false,
    entityCompletionStatus.numOfStates,
    false
  );

  const completedFederalStepsSet = new Set(completedFederalSteps);
  const completedStateStepsSet = new Set(completedStateSteps);

  const shouldShowFederalRTP =
    !isUserReviewer ||
    (completedFederalStepsSet.has('rtp') &&
      completedFederalStepsSet.has('credits') &&
      completedFederalStepsSet.has('tax-effected') &&
      completedFederalStepsSet.has('temporary') &&
      completedFederalStepsSet.has('permanent'));

  const shouldShowFederalDeferred =
    !isUserReviewer ||
    (completedFederalStepsSet.has('deferred') &&
      completedFederalStepsSet.has('temporary') &&
      completedFederalStepsSet.has('credits') &&
      completedFederalStepsSet.has('nol'));

  const shouldShowStateRTP =
    !isUserReviewer ||
    (completedStateStepsSet.has('rtp') &&
      completedStateStepsSet.has('credits') &&
      completedStateStepsSet.has('modifications') &&
      completedStateStepsSet.has('tax-effected') &&
      completedFederalStepsSet.has('ptbi') &&
      completedFederalStepsSet.has('rtp'));

  const shouldShowStateDeferred =
    !isUserReviewer ||
    (completedStateStepsSet.has('deferred') &&
      completedStateStepsSet.has('modifications.temporary') &&
      completedStateStepsSet.has('credits') &&
      completedStateStepsSet.has('apportionment') &&
      completedStateStepsSet.has('nol') &&
      completedFederalStepsSet.has('deferred') &&
      completedFederalStepsSet.has('temporary') &&
      completedFederalStepsSet.has('permanent') &&
      completedFederalStepsSet.has('credits'));

  if (!shouldShowFederalRTP && isUserReviewer) {
    const index = federalStepsCopy.indexOf('rtp');
    federalStepsCopy.splice(index, 1);
  }

  if (!shouldShowFederalDeferred && isUserReviewer) {
    const index = federalStepsCopy.indexOf('deferred');
    federalStepsCopy.splice(index, 1);
  }

  if (!shouldShowStateRTP && isUserReviewer) {
    const index = stateStepsCopy.indexOf('rtp');
    federalStepsCopy.splice(index, 1);
  }

  if (!shouldShowStateDeferred && isUserReviewer) {
    const index = stateStepsCopy.indexOf('deferred');
    federalStepsCopy.splice(index, 1);
  }

  return {
    federalStepsCopy,
    stateStepsCopy,
    completedFederalSteps,
    completedStateSteps,
    inProgressFederalSteps,
    inProgressStateSteps
  };
};

export const isEntityFullyComplete = (entity: Entity, completionStatus: CompletionStatus) => {
  completionStatus = completionStatus ?? {};
  const federal = completionStatus.federal ?? {};
  const state = completionStatus.state ?? {};
  const hasCorrectNumberOfFederalSteps = Object.keys(federal).length === 9;
  const hasCorrectNumberOfStateSteps = Object.keys(state).length === 8;
  const federalStepsAreAllComplete = Object.values(federal).every((value: any) => Object.values(value)[0]);
  const numberOfStates = entity.subJurisdictionIds.length;
  const stateStepsAreAllComplete = Object.entries(state).every(([key, value]) =>
    ['rtp', 'nol', 'credits', 'accounts'].includes(key)
      ? Object.values(value ?? {}).length === numberOfStates && Object.values(value ?? {}).every((val) => val)
      : Object.values(value ?? {})[0]
  );
  const isEntityForeign = !entity.hasStates;

  if (isEntityForeign) {
    return hasCorrectNumberOfFederalSteps && federalStepsAreAllComplete;
  }

  return (
    hasCorrectNumberOfFederalSteps &&
    federalStepsAreAllComplete &&
    hasCorrectNumberOfStateSteps &&
    stateStepsAreAllComplete
  );
};

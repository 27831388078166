import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, CircularProgress, Table as MuiTable, TableContainer, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { TableBody, TableBodyWithInView, TableHead } from './components';
import { Column, Row, TableProps } from './Table.proptype';
import { filterAndSort, ASC, DESC, OUTLINED, FLAT, STYLED_CELL } from './utils';

import { useCategorizableSections } from '../../hooks/useCategorizableSections';
import { selectIsEntityDetailsGroupedViewToggled } from '../../selectors';
import { splitLevelStep } from '../../utils/category';

interface StyleProps {
  isNotEditableShaded?: boolean;
  isBackgroundColorWhite?: boolean;
  isGroupedViewToggleOn?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  table: ({ isGroupedViewToggleOn, isBackgroundColorWhite = true }: StyleProps) => ({
    '& .MuiTableCell-root': {
      padding: theme.spacing(1.2),
      backgroundColor: isBackgroundColorWhite ? theme.palette.common.white : undefined,
      whiteSpace: 'pre-wrap',
      '&.active-row.active-column': {
        backgroundColor: theme.palette.action.selected
      }
    },
    '& .MuiTableCell-head': {
      color: theme.palette.text.secondary
    },
    '& .MuiTableHead-root > tr:not(:first-child) .MuiTableCell-head': {
      padding: theme.spacing(0, 1.2, 1.2)
    },
    '& .MuiTableHead-root': {
      pointerEvents: isGroupedViewToggleOn ? 'none' : 'auto'
    },
    '& .checkbox': {
      width: '30px',
      textAlign: 'center',

      '& .MuiButtonBase-root': {
        padding: theme.spacing(0.5),

        '&.Mui-checked.Mui-disabled': {
          opacity: 0.5
        }
      },
      '&.MuiTableCell-head .MuiButtonBase-root': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.action.hover,
        padding: theme.spacing(1, 1.5)
      },
      '&.MuiTableCell-head, &.MuiTableCell-body': {
        padding: theme.spacing(0, 1)
      }
    },
    '& .MuiTableCell-body.sticky': {
      position: 'sticky',
      left: 0
    },
    '& .MuiTableCell-head.sticky': {
      zIndex: 3
    },
    '& .MuiTableCell-body.sortable-numerical': {
      paddingRight: theme.spacing(4)
    },
    '& .MuiTableRow-hover:hover:not(.new-row) .MuiTableCell-body.MuiTableCell-root:not(:focus-within)': {
      // can't use theme.palette.action.hover because it is semi-transparent, and doesn't play well with sticky columns
      backgroundColor: '#f5f5f5'
    },
    '& .selected:not(:hover) .MuiTableCell-body.MuiTableCell-root': {
      // can't use theme.palette.action.selected because it is semi-transparent, and doesn't play well with sticky columns
      backgroundColor: '#ebebeb'
    },
    '& .MuiTableCell-body.no-rows': {
      padding: theme.spacing(3),
      textAlign: 'center',
      borderBottom: 'none'
    },
    '& .editable.MuiTableCell-body.MuiTableCell-root': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      padding: 0
    },
    '& .total .MuiTableCell-body.MuiTableCell-root': {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.text.secondary,
      borderTop: `2px solid ${theme.palette.grey[400]}`,
      borderBottom: `2px solid ${theme.palette.grey[400]}`,
      fontWeight: theme.typography.fontWeightBold,
      paddingRight: '14.4px'
    },
    '& .category-total .MuiTableCell-body.MuiTableCell-root': {
      fontWeight: 'bolder',
      borderTop: '1px solid black',
      borderBottom: '1px solid black'
    },
    '& .space .MuiTableCell-body.MuiTableCell-root': {
      backgroundColor: 'white!important',
      color: 'white',
      height: '3px',
      border: 'none',
      padding: '0'
    },
    '& .float-right': {
      float: 'right'
    },
    '& .float-left': {
      float: 'left'
    }
  }),
  [FLAT]: {
    '& .MuiTableCell-body:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    '& .MuiTableCell-body:not(:only-child)': {
      backgroundColor: ({ isNotEditableShaded = true }: StyleProps) =>
        isNotEditableShaded ? theme.palette.grey[50] : theme.palette.common.white,
      color: ({ isNotEditableShaded = true }: StyleProps) =>
        theme.palette.text[isNotEditableShaded ? 'secondary' : 'primary']
    },
    [STYLED_CELL]: {
      '& .MuiTableCell-body:not(:only-child)': {
        backgroundColor: ({ isNotEditableShaded = true }: StyleProps) =>
          isNotEditableShaded ? theme.palette.grey[50] : theme.palette.common.white,
        color: ({ isNotEditableShaded = true }: StyleProps) =>
          theme.palette.text[isNotEditableShaded ? 'secondary' : 'primary']
      }
    }
  },
  [OUTLINED]: {
    '& .MuiTableCell-body.no-rows:first-child, & .MuiTableCell-body.no-rows:last-child': {
      borderColor: 'transparent'
    },
    '& .MuiTableCell-body:first-child, & .checkbox + .MuiTableCell-body': {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
    },
    '& .MuiTableCell-body:last-child': {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`
    },
    '& .MuiTableCell-root.checkbox': {
      borderColor: 'transparent'
    },
    '& .MuiTableRow-hover:hover .MuiTableCell-root.checkbox, & .selected .MuiTableCell-root.MuiTableCell-body.checkbox': {
      backgroundColor: theme.palette.common.white
    },
    '& .MuiTableCell-body': {
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(0.7, 1.2)
    },
    '& .MuiTableCell-head': {
      borderBottom: 'none',
      padding: theme.spacing(0.7, 1.2)
    },
    '& .filter.MuiTableCell-head': {
      paddingLeft: 0
    }
  },
  outlineSeparator: {
    '& .MuiTableCell-body.MuiTableCell-root': {
      border: 'none',
      padding: theme.spacing(0.5, 0)
    }
  },
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  sectionHeader: {
    '&.MuiTableCell-root': {
      padding: theme.spacing(3, 0, 1, 1)
    }
  },
  colGroups: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      padding: theme.spacing(0, 1, 0.8),
      position: 'relative'
    }
  },
  colGroup: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(0.5, 1.5),
    fontWeight: theme.typography.fontWeightBold
  }
}));

const INITIAL_SORT = { column: null, dir: ASC };

const Table = (props: TableProps) => {
  const {
    columns,
    rows,
    className,
    variant = FLAT,
    onFilter,
    onSort,
    onSelect,
    onDisplayedRows,
    renderError,
    reportName,
    selected = []
  } = props;
  const { pathname } = useLocation();
  const { level, step } = splitLevelStep(pathname);
  const categorizableSections = useCategorizableSections(level, step);
  const isCategorizableTab = Boolean(categorizableSections);
  const isGroupedViewToggleOn = useSelector(selectIsEntityDetailsGroupedViewToggled) && isCategorizableTab;
  const classes: Record<string, string> = useStyles({ ...props, isGroupedViewToggleOn });
  const [filters, setFilters] = useState(props.filters || new Map());
  const [sort, setSort] = useState(props.sort ?? INITIAL_SORT);
  const [filteredRows, setFilteredRows] = useState<Row[]>();
  const { prov4477IntersectionObserverTable } = useFlags();

  useEffect(() => {
    setFilters(props.filters || new Map());
  }, [props.filters]);
  useEffect(() => {
    setSort(props.sort ?? INITIAL_SORT);
  }, [props.sort, isGroupedViewToggleOn]);

  useEffect(() => {
    if (isGroupedViewToggleOn) {
      setSort(INITIAL_SORT);
    }
  }, [isGroupedViewToggleOn]);

  function handleFilterChange(column: Column, searched: string | number) {
    if (column?.field) {
      if (searched) {
        filters.set(column.field, { searched, column });
      } else {
        filters.delete(column.field);
      }

      const newFilters = new Map(filters.entries());
      setFilters(newFilters);
      if (onFilter) {
        onFilter(newFilters);
      }
    }
  }

  function handleSortClick(column: Column | null) {
    const newSort =
      sort.column === null
        ? { column, dir: ASC } // set new active column
        : sort.column === column && sort.dir === ASC
        ? { column, dir: DESC } // switch ascending to descending on active column
        : sort.column !== column && sort.column !== null
        ? { column, dir: ASC } // set different active column
        : INITIAL_SORT; // de-activate column

    setSort(newSort);
    onSort?.(newSort);
  }

  useEffect(() => {
    const displayed = filterAndSort(rows, filters, sort);
    setFilteredRows(displayed);
    if (onDisplayedRows) {
      onDisplayedRows(displayed);
    }
  }, [filters, sort, rows, onDisplayedRows]);

  return columns ? (
    <TableContainer className={`${classes.container} ${className ?? ''}`}>
      <MuiTable stickyHeader className={`${classes.table} ${classes[variant]}`}>
        <TableHead
          {...props}
          classes={classes}
          selected={selected}
          sort={sort}
          filters={filters}
          onFilterChange={handleFilterChange}
          onSortClick={handleSortClick}
          onSelectChange={onSelect}
        />
        {renderError ? (
          <TableRow>No Data Available.</TableRow>
        ) : prov4477IntersectionObserverTable ? (
          <TableBodyWithInView
            {...props}
            selected={selected}
            classes={classes}
            rows={filteredRows}
            reportName={reportName}
            onSelectChange={onSelect}
          />
        ) : (
          <TableBody
            {...props}
            selected={selected}
            classes={classes}
            rows={filteredRows}
            reportName={reportName}
            onSelectChange={onSelect}
          />
        )}
      </MuiTable>
    </TableContainer>
  ) : (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

Table.ASC = ASC;
Table.DESC = DESC;

export default Table;

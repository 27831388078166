import { TFunction } from 'react-i18next';

import { calculateEndingBalanceForDeferred } from '../../../../calculations';
import { makeListExtractor, sum } from '../../../../calculations/utils';
import { StepCompletionStatusReturnType } from '../../../../models';
import { groupByCreditName, sortOnColumn } from '../../../../utils';
import { Column } from '../../../Table/Table.proptype';
import { Source, ValuationAllowance } from '../../models';
import {
  computeFederalGrossTemporaryDifference,
  emptySectionHeader,
  makeSectionTitle,
  VALUATION_ALLOWANCE_ROW_NAME
} from '../../utils';

export const NET_OPERATING_LOSS_ROW_NAME = 'Federal Net Operating Loss';

export const grossTemporaryDifferencesCreditName = 'federal.temporary';
export const grossFederalNolCreditName = 'federal.nol';
export const taxCreditsCreditName = 'federal.credits';

export const extractListsForFederalDeferred = makeListExtractor('data', [
  'balanceSheetOnlyAdjustment',
  'manualEndingBalance',
  'beginningBalance',
  'deferredOnlyAdjustment',
  'm1Adjustment',
  'rtp',
  'generatedAmount',
  'usedAmount',
  'oci',
  'goodwill',
  'fin48'
]);

export const calculateFederalDeferredRowsSum = (inputs = {}) => {
  const {
    balanceSheetOnlyAdjustmentList,
    beginningBalanceList,
    deferredOnlyAdjustmentList,
    generatedAmountList,
    m1AdjustmentList,
    manualEndingBalanceList,
    rtpList,
    usedAmountList,
    ociList,
    goodwillList,
    fin48List
  } = extractListsForFederalDeferred(inputs);

  const balanceSheetOnlyAdjustment = sum(balanceSheetOnlyAdjustmentList);
  const beginningBalance = sum(beginningBalanceList);
  const deferredOnlyAdjustment = sum(deferredOnlyAdjustmentList);
  const generatedAmount = sum(generatedAmountList);
  const manualEndingBalance = sum(manualEndingBalanceList);
  const rtp = sum(rtpList);
  const usedAmount = sum(usedAmountList);
  const m1Adjustment = sum(m1AdjustmentList);
  const oci = sum(ociList);
  const goodwill = sum(goodwillList);
  const fin48 = sum(fin48List);

  return {
    ...inputs,
    balanceSheetOnlyAdjustment,
    beginningBalance,
    deferredOnlyAdjustment,
    generatedAmount,
    m1Adjustment,
    manualEndingBalance,
    rtp,
    usedAmount,
    oci,
    goodwill,
    fin48
  };
};

export const computeGrossFederalNetOperatingLoss = (
  t: TFunction,
  nol: Source['grossFederalNol'],
  deferred: Source['deferred'],
  valuationAllowance: ValuationAllowance,
  stepCompletionStatus: StepCompletionStatusReturnType['status']
  // eslint-disable-next-line max-params
) => {
  const grossFederalNol = nol.map((nolRow) => {
    return {
      ...nolRow,
      m1Adjustment: Number(nolRow.usedAmount ?? 0) + Number(nolRow.generatedAmount ?? 0)
    };
  });

  const totalGrossFederalNol = calculateFederalDeferredRowsSum({ data: grossFederalNol });
  const deferredTotalGrossFederalNol = deferred.find(
    ({ creditName, name }) => creditName === grossFederalNolCreditName && name === NET_OPERATING_LOSS_ROW_NAME
  );

  const mergedGrossFederalNol = {
    ...totalGrossFederalNol,
    ...deferredTotalGrossFederalNol
  };

  const grandTotalGrossFederalNol = calculateFederalDeferredRowsSum({
    data: [mergedGrossFederalNol, valuationAllowance.grossFederalNol]
  });

  return [
    makeSectionTitle(t('Gross Federal Net Operating Loss')),
    {
      name: t(NET_OPERATING_LOSS_ROW_NAME),
      creditName: grossFederalNolCreditName,
      nonEditableFields: [
        'beginningBalance',
        'deferredOnlyAdjustment',
        'oci',
        'goodwill',
        'fin48',
        'balanceSheetOnlyAdjustment'
      ],
      ...mergedGrossFederalNol,
      categorizable: false
    },
    emptySectionHeader,
    {
      creditName: grossFederalNolCreditName,
      isEditable: !stepCompletionStatus,
      name: t(VALUATION_ALLOWANCE_ROW_NAME),
      nonEditableFields: ['endingBalance', 'difference'],
      ...valuationAllowance.grossFederalNol,
      categorizable: false
    },
    {
      name: t('Gross Federal Net Operating Loss, Net of Valuation Allowance'),
      isTotal: true,
      ...grandTotalGrossFederalNol,
      categorizable: false
    }
  ];
};

export const computeTaxCredits = (
  t: TFunction,
  columns: Column[],
  credits: Source['taxCredits'],
  deferred: Source['deferred'],
  valuationAllowance: ValuationAllowance,
  stepCompletionStatus: StepCompletionStatusReturnType['status']
  // eslint-disable-next-line max-params
) => {
  const creditNames = groupByCreditName(credits);
  const taxCredits = [];
  for (const [creditName, creditRows] of creditNames.entries()) {
    const creditRow = calculateFederalDeferredRowsSum({ data: creditRows });
    const deferredRow = deferred.find((row) => row.name === creditName && row.creditName === taxCreditsCreditName);
    taxCredits.push({
      ...creditRow,
      ...deferredRow,
      creditName: taxCreditsCreditName,
      name: creditName,
      nonEditableFields: [
        'beginningBalance',
        'deferredOnlyAdjustment',
        'oci',
        'goodwill',
        'fin48',
        'balanceSheetOnlyAdjustment'
      ],
      m1Adjustment: Number(creditRow.usedAmount) + Number(creditRow.generatedAmount),
      categorizable: false
    });
  }

  const totalTaxCredits = calculateFederalDeferredRowsSum({ data: taxCredits });
  const grandTotalTaxCredits = calculateFederalDeferredRowsSum({
    data: [totalTaxCredits, valuationAllowance.taxCredits]
  });

  return [
    makeSectionTitle(t('Tax Credits')),
    ...sortOnColumn(taxCredits, columns[0]),
    {
      name: t('Total Tax Credits'),
      isTotal: true,
      ...totalTaxCredits,
      categorizable: false
    },
    emptySectionHeader,
    {
      creditName: taxCreditsCreditName,
      isEditable: !stepCompletionStatus,
      name: t(VALUATION_ALLOWANCE_ROW_NAME),
      nonEditableFields: ['endingBalance', 'difference'],
      ...valuationAllowance.taxCredits,
      categorizable: false
    },
    {
      name: t('Tax Credits, Net of Valuation Allowance'),
      isTotal: true,
      ...grandTotalTaxCredits,
      categorizable: false
    }
  ];
};

export const makeRows = (
  t: TFunction,
  columns: Column[],
  source: Source,
  valuationAllowance: ValuationAllowance,
  stepCompletionStatus: StepCompletionStatusReturnType['status'],
  dispatch?: any,
  className?: string,
  filterAdjustmentsByRowId = false
  // eslint-disable-next-line max-params
) => {
  if (!source) {
    return [];
  }

  const grossTemporaryDifferenceRows = computeFederalGrossTemporaryDifference(
    t,
    columns,
    source.balanceSheet,
    source.incomeStatement,
    source.returnToProvision,
    source.deferred,
    valuationAllowance,
    stepCompletionStatus,
    dispatch,
    className,
    filterAdjustmentsByRowId
  );

  const grossNetOperatingLossRows = computeGrossFederalNetOperatingLoss(
    t,
    source.grossFederalNol,
    source.deferred,
    valuationAllowance,
    stepCompletionStatus
  );

  const taxCreditRows = computeTaxCredits(
    t,
    columns,
    source.taxCredits,
    source.deferred,
    valuationAllowance,
    stepCompletionStatus
  );

  return [...grossTemporaryDifferenceRows, ...grossNetOperatingLossRows, ...taxCreditRows].map((row) =>
    calculateEndingBalanceForDeferred(row)
  );
};

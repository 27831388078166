import { useEffect, useMemo, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { makeRows } from './utils';

import { ContentAndTitle, TabTitle } from '..';
import { TableWithComment } from '../../..';
import { FEDERAL_UUID, LEVELS, TABS_WITH_IMPORT_BUTTON_AT_TOP } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData, useValuationAllowance } from '../../../../hooks';
import { FederalTabProps, FinancialInfo, Step, Row } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import { selectDoesUserHaveRole } from '../../../../selectors';
import LoadingWrapper from '../../../LoadingWrapper';
import { Column, FailedCells, TableProps } from '../../../Table/Table.proptype';
import { renderValue } from '../../../Table/utils';
import { ProReadOnly, ProReviewer } from '../../../UserRoleStylesProvider/constants';
import { DeferredRow } from '../../models';
import {
  handleEditRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  getDeferredColumns,
  getRowNamesFromLevelSteps,
  getTableDataFromFinancialData,
  EntityNumberRouteMatch,
  getDeferredColumnsFlagged,
  getRowsFromLevelSteps,
  getTableDataByRowIdFinancialData
} from '../../utils';
import { getLinkButtonCssProps } from '../../utils/styles';

const useStyles = makeStyles((theme) => ({
  linkButton: ({ isUserReadOnly }: { isUserReadOnly: boolean }) => ({
    ...getLinkButtonCssProps(theme, isUserReadOnly),
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'inherit'
    }
  })
}));

const LEVEL = LEVELS.FEDERAL;
const STEP = 'deferred';
const LevelAndStep = `${LEVEL as string}.${STEP}`;

const CREDITS_STEP: Step = 'credits';
const NOL_STEP: Step = 'nol';
const RTP_STEP: Step = 'rtp';
const BALANCE_SHEET_STEP: Step = 'temporary.balanceSheet';
const INCOME_STATEMENT_STEP: Step = 'temporary.incomeStatement';

const STEPS: Step[] = [STEP, NOL_STEP, CREDITS_STEP, RTP_STEP, BALANCE_SHEET_STEP, INCOME_STATEMENT_STEP];

const nolAndCreditsImportColumns = new Set([
  'balanceSheetOnlyAdjustment',
  'beginningBalance',
  'deferredOnlyAdjustment',
  'generatedAmount',
  'oci',
  'goodwill',
  'fin48',
  'rtp',
  'usedAmount'
]);

const returnToProvisionImportColumns = new Set(['taxProvision', 'taxReturn', 'm1Adjustment']);
const temporaryImportColumns = new Set([
  'beginningBalance',
  'beginningPayments',
  'bookBalance',
  'endingBalance',
  'endingPayments',
  'taxBalance'
]);

const FederalDeferred = ({ entityId }: FederalTabProps) => {
  const {
    prov2826TarfEnhancements,
    prov3341FilterEmptyRowsTable,
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov4438FilterAdjustmentsByRowId: filterAdjustmentsByRowId
  } = useFlags();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isUserReadOnly = useSelector(selectDoesUserHaveRole([ProReadOnly.Name, ProReviewer.Name]));
  const classes = useStyles({ isUserReadOnly });
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const [rows, setRows] = useState<DeferredRow[]>([]);
  const [hideEmptyRows, setHideEmptyRows] = useState(prov3341FilterEmptyRowsTable);
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LevelAndStep, FEDERAL_UUID);

  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS);

  const returnToProvisionFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${RTP_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        returnToProvisionImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const balanceSheetFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${BALANCE_SHEET_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        temporaryImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const incomeStatementFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${INCOME_STATEMENT_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        temporaryImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const grossFederalNolFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${NOL_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        nolAndCreditsImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const taxCreditsFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${CREDITS_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        nolAndCreditsImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const deferredFinancialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);

  const rowsFromLevelSteps = useMemo(
    (): any =>
      filterAdjustmentsByRowId
        ? getRowsFromLevelSteps(tabsData, LEVEL, STEPS)
        : getRowNamesFromLevelSteps(tabsData, LEVEL, STEPS),
    [filterAdjustmentsByRowId, tabsData]
  );

  const source = useMemo(() => {
    return {
      deferred: getTableDataByRowIdFinancialData(
        LEVEL,
        'deferred',
        deferredFinancialInfo,
        filterAdjustmentsByRowId,
        tabsData
      ),
      grossFederalNol: getTableDataFromFinancialData(rowsFromLevelSteps.nol, grossFederalNolFinancialInfo),
      returnToProvision: getTableDataByRowIdFinancialData(
        LEVEL,
        'rtp',
        returnToProvisionFinancialInfo,
        filterAdjustmentsByRowId,
        tabsData
      ),
      taxCredits: getTableDataFromFinancialData(rowsFromLevelSteps.credits, taxCreditsFinancialInfo),
      balanceSheet: getTableDataByRowIdFinancialData(
        LEVEL,
        BALANCE_SHEET_STEP,
        balanceSheetFinancialInfo,
        filterAdjustmentsByRowId,
        tabsData
      ),

      incomeStatement: getTableDataByRowIdFinancialData(
        LEVEL,
        INCOME_STATEMENT_STEP,
        incomeStatementFinancialInfo,
        filterAdjustmentsByRowId,
        tabsData
      )
    };
  }, [
    balanceSheetFinancialInfo,
    deferredFinancialInfo,
    filterAdjustmentsByRowId,
    grossFederalNolFinancialInfo,
    incomeStatementFinancialInfo,
    returnToProvisionFinancialInfo,
    rowsFromLevelSteps.credits,
    rowsFromLevelSteps.nol,
    tabsData,
    taxCreditsFinancialInfo
  ]);

  const valuationAllowance = useValuationAllowance(source.deferred);
  const isTarfFlagActive = prov2826TarfEnhancements;

  const renderAdjNameWithAccNumCell = useCallback(
    (
      row: Row,
      value: number,
      column: Column,
      failedCells: FailedCells,
      onCellChange: TableProps['onCellChange'],
      onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
      onCellClickFiller: TableProps['onCellClick'], // Here because there are problems around shared render functions
      renderOpts: any,
      rows: Row[],
      onCellClick?: TableProps['onCellClick']
      // eslint-disable-next-line max-params
    ) => {
      const adjNameWithAccountNumber = (accountNumber: string) => {
        return (
          <>
            <div
              style={{
                color: 'rgb(153, 164, 181)',
                fontSize: '12px',
                fontStyle: 'italic'
              }}
            >
              {accountNumber}
            </div>
            {row?.renderCell
              ? row.renderCell(
                  row,
                  value,
                  column,
                  failedCells,
                  onCellChange,
                  onCellOrCommentBlur,
                  onCellClick,
                  renderOpts,
                  rows,
                  '',
                  onCellClick
                )
              : renderValue(value, column, renderOpts, row, '')}
          </>
        );
      };

      if (row?.accountNumber && filterAdjustmentsByRowId) {
        return adjNameWithAccountNumber(row.accountNumber);
      }

      if (row?.renderCell) {
        return row.renderCell(
          row,
          value,
          column,
          failedCells,
          onCellChange,
          onCellOrCommentBlur,
          onCellClick,
          renderOpts,
          rows,
          '',
          onCellClick
        );
      }

      return renderValue(value, column, renderOpts, row, '');
    },
    [filterAdjustmentsByRowId]
  );

  const columns = useMemo(() => {
    return isTarfFlagActive
      ? getDeferredColumnsFlagged(t, stepCompletionStatus.status || isUserReadOnly, renderAdjNameWithAccNumCell)
      : getDeferredColumns(t, stepCompletionStatus.status || isUserReadOnly, renderAdjNameWithAccNumCell);
  }, [isTarfFlagActive, isUserReadOnly, stepCompletionStatus.status, t, renderAdjNameWithAccNumCell]);

  useEffect(() => {
    const rows = makeRows(
      t,
      columns,
      source,
      valuationAllowance,
      stepCompletionStatus.status || isUserReadOnly,
      dispatch,
      classes.linkButton,
      filterAdjustmentsByRowId
    );

    if (!hideEmptyRows) {
      setRows(rows);
      return;
    }

    // Remove this section after virtualize tables is done (flag is prov3341FilterEmptyRowsTable)
    const filterEmptyRows = rows.filter((row: any) => {
      const emptyValues = new Set([0, undefined, null]);

      if (
        row.creditName === 'federal.temporary.balanceSheet' ||
        row.creditName === 'federal.temporary.incomeStatement'
      ) {
        const fieldsToCheck =
          row.creditName === 'federal.temporary.balanceSheet'
            ? [
                'beginningAdjusted',
                'beginningBalance',
                'difference',
                'endingAdjusted',
                'endingBalance',
                'm1Adjustment',
                'manualEndingBalance',
                'rtp'
              ]
            : ['beginningBalance', 'bookBalance', 'difference', 'endingBalance', 'm1Adjustment', 'rtp', 'taxBalance'];

        return !fieldsToCheck.every((field) => emptyValues.has(row[field]));
      }

      return row;
    });

    setRows(filterEmptyRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columns,
    isUserReadOnly,
    stepCompletionStatus.status,
    source,
    t,
    valuationAllowance,
    classes.linkButton,
    hideEmptyRows
  ]);

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo: deferredFinancialInfo,
    level: LEVEL,
    rows,
    setRows,
    step: STEP as Step,
    t,
    filterAdjustmentsByRowId
  };

  const handleCheckBoxOnClick = () => {
    setHideEmptyRows(!hideEmptyRows);
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('Deferred Rollforward')}
            isCompleted={stepCompletionStatus.status}
            shouldDisplayDataImportButton={TABS_WITH_IMPORT_BUTTON_AT_TOP.federal.deferred}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <>
          {prov3341FilterEmptyRowsTable && (
            <div>
              Hide Empty Rows:
              <Checkbox checked={hideEmptyRows} onClick={handleCheckBoxOnClick} />
            </div>
          )}
          <TableWithComment
            columns={columns}
            failedCells={failedCells}
            rows={rows}
            hideActionsMenu={stepCompletionStatus.status}
            onCellChange={(params) => {
              if (!isPerformanceFixEnabled) {
                handleEditRowForEntityDetails({
                  ...dataForMethods,
                  ...params
                });
              }
            }}
            onCellOrCommentBlur={(params) => {
              if (isPerformanceFixEnabled) {
                handleEditRowForEntityDetails({
                  ...dataForMethods,
                  ...params
                });
              }

              const isDynamicAdj =
                filterAdjustmentsByRowId &&
                ['federal.temporary.incomeStatement', 'federal.temporary.balanceSheet'].includes(
                  params.row?.creditName
                );
              if (!params.row.sourceRowId && isDynamicAdj) {
                params.row.sourceRowId = params.row.rowId;
                params.row.rowId = undefined;
              }

              handleOnCellOrCommentBlurForEntityDetails({
                ...dataForMethods,
                ...params
              });
            }}
          />
        </>
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default FederalDeferred;

import {
  calculateM1Adjustment,
  calculateBalanceSheet,
  calculateIncomeStatement,
  CalculateIncomeStatementReturn
} from '../../../../../calculations';
import { FinancialInfoTabsData } from '../../../../../models';
import { RowForGetTableDataFromFinancialData } from '../../../../../utils';
import { getStepBasedTableDataFromFinancialData, getRowValues } from '../../../utils';
import { MODIFICATIONS_STEP } from '../constants';
import { StateModificationsRow } from '../StateModifications.types';

export const getValuesFromMatchingKeys = (source: FinancialInfoTabsData, matcher: string) =>
  Object.keys(source)
    .filter((key) => key.startsWith(matcher))
    .flatMap((key) => source[key]);

export const getFinancialInfo = (source: FinancialInfoTabsData, matchers: string[]) =>
  Object.keys(source)
    .filter((key) => matchers.includes(key))
    .flatMap((key) => {
      return source[key];
    });

type ModificationTypeOption = {
  name: string;
  value: string;
};
type ModificationTypeOptions = ModificationTypeOption[];

export const getModificationTypeOptions = (step: string): ModificationTypeOptions => [
  { name: 'Permanent', value: `${step}.permanent` },
  { name: 'Temporary', value: `${step}.temporary` }
];

export const getIsRowNameUsedInMatchingStepValues = (rows: any, step: any, rowName: string) =>
  rows
    .filter((item: any) => item.step === step)
    .map(({ name }: any) => name)
    .includes(rowName);

export const getIsRowIdUsedInMatchingStepValues = (rows: any, step: any, rowIdToCompare: string) =>
  rows
    .filter((item: any) => item.step === step)
    .map(({ sourceRowId }: any) => sourceRowId)
    .includes(rowIdToCompare);

const getUniqueObjectsByName = (arr: any) => {
  const uniqueMap = new Map();
  return arr.filter((obj: any) => {
    if (!uniqueMap.has(obj.rowId)) {
      uniqueMap.set(obj.rowId, true);
      return true;
    }

    return false;
  });
};

export const getStepBasedNameDropdownOptions = ({
  row,
  tabsData,
  rows,
  filterAdjustmentsByRowId
}: {
  row: StateModificationsRow;
  tabsData: FinancialInfoTabsData;
  rows?: StateModificationsRow[];
  filterAdjustmentsByRowId?: boolean;
}) => {
  const step: string = row.step?.split('.').pop() ?? 'undefined';
  const stepForFederalData = `federal.${step}`;

  if (filterAdjustmentsByRowId) {
    const namesFromMatchingKeys = getValuesFromMatchingKeys(tabsData, stepForFederalData).map((obj) => {
      return { rowName: obj.rowName, accountNumber: obj.accountNumber, sourceRowId: obj.rowId, rowId: obj.rowId };
    });
    if (row.isNew && row.name) {
      namesFromMatchingKeys.push({ rowName: row.name, accountNumber: undefined, sourceRowId: '', rowId: '' });
    }

    const nameOptions = getUniqueObjectsByName(namesFromMatchingKeys).map((obj: any) => {
      return {
        rowId: obj.rowId,
        name: obj.rowName,
        value:
          obj?.accountNumber && obj?.rowName ? `${String(obj.rowName)} - ${String(obj.accountNumber)}` : obj.rowName,
        accountNumber: obj.accountNumber,
        disabled: getIsRowIdUsedInMatchingStepValues(rows, row.step, obj.rowId)
      };
    });

    return nameOptions;
  }

  const namesFromMatchingKeys = getValuesFromMatchingKeys(tabsData, stepForFederalData).map((obj) => obj.rowName);
  if (row.isNew && row.name) {
    namesFromMatchingKeys.push(row.name);
  }

  const nameOptions = [...new Set(namesFromMatchingKeys)].map((name) => {
    return {
      name,
      value: name,
      disabled: getIsRowNameUsedInMatchingStepValues(rows, row.step, name)
    };
  });

  return nameOptions;
};

export const getValuesFromTypeAndAdjustment = (source: FinancialInfoTabsData, step: string, name?: string) =>
  Object.keys(source)
    .filter((key) => key.startsWith(step))
    .flatMap((key) => source[key])
    .find(({ rowName }) => rowName === name);

export const calculateM1AdjustmentValue = ({
  filterAdjustmentsByRowId,
  row = {},
  tabsData = {}
}: {
  filterAdjustmentsByRowId: boolean;
  row: StateModificationsRow;
  tabsData: FinancialInfoTabsData;
}) => {
  const value = getFederalM1AmountCalculatedValue({ filterAdjustmentsByRowId, row, tabsData });
  if (value) {
    return Number(row.amount ?? 0) - Number(value);
  }

  return row.amount ?? 0;
};

export const getStepValue = (row: StateModificationsRow) => {
  const namedValue = getModificationTypeOptions(MODIFICATIONS_STEP).find((obj) => obj.value === row.step)?.name;
  return row.isNew ? row.step ?? '' : namedValue ?? '';
};

const getCalculatedFederalM1AdjustmentByStep = (federalAdjustmentRow: any) => {
  switch (federalAdjustmentRow?.step?.split('.').slice(0, 2).join('.')) {
    case 'permanent':
      return federalAdjustmentRow ? calculateM1Adjustment(federalAdjustmentRow) : 0;
    case 'temporary.incomeStatement':
      return federalAdjustmentRow
        ? calculateIncomeStatement(federalAdjustmentRow as CalculateIncomeStatementReturn).m1Adjustment
        : 0;
    case 'temporary.balanceSheet':
      return federalAdjustmentRow ? calculateBalanceSheet(federalAdjustmentRow).m1Adjustment : 0;
    default:
      return 0;
  }
};

export const getFederalM1AmountCalculatedValue = ({
  filterAdjustmentsByRowId,
  row = {},
  tabsData = {}
}: {
  filterAdjustmentsByRowId: boolean;
  row: StateModificationsRow;
  tabsData: FinancialInfoTabsData;
}) => {
  const step: string = row.step?.split('.').pop() ?? 'undefined';
  const financialInfo = getValuesFromMatchingKeys(tabsData, `federal.${step}`);
  const simpleNameAndStepObjects = [
    ...new Set(financialInfo.map((obj) => ({ name: obj.rowName, step: obj.step, rowId: obj.rowId })))
  ];
  const rowsWithDataAndStep = getStepBasedTableDataFromFinancialData(
    simpleNameAndStepObjects,
    financialInfo,
    filterAdjustmentsByRowId
  );

  const matchingFederalRow = rowsWithDataAndStep.find((data: RowForGetTableDataFromFinancialData) => {
    return filterAdjustmentsByRowId ? data.rowId === row.sourceRowId : data.name === row.name;
  });

  if (matchingFederalRow?.step.includes('temporary.balanceSheet')) {
    // add in taxProvision and taxReturn for rtp calculation
    const rtpRowPreCalculation = getRowValues({
      rowName: matchingFederalRow.name,
      financialData: tabsData['federal.rtp'],
      creditName: matchingFederalRow.creditName
    });
    const { taxProvision, taxReturn } = rtpRowPreCalculation;

    return getCalculatedFederalM1AdjustmentByStep({ ...matchingFederalRow, taxProvision, taxReturn });
  }

  return getCalculatedFederalM1AdjustmentByStep(matchingFederalRow);
};

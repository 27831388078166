import { useEffect, useMemo, useState, useRef, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContentAndTitle, TabTitle } from '..';
import { TableWithComment } from '../../..';
import { ReactComponent as NotEqualIcon } from '../../../../assets/img/IconNotEqual.svg';
import { calculateEndingBalanceForCredits } from '../../../../calculations';
import { FEDERAL_UUID, LEVELS, reservedNames } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { FederalTabProps, Level, Step, Column, Row } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import { setInterlinkedDataWarningEntities } from '../../../../redux/ui';
import HTTPService, { LambdaResponse } from '../../../../services/http';
import LoadingWrapper from '../../../LoadingWrapper';
import { renderCell } from '../../../Table/components/TableBody';
import { FailedCells, TableProps } from '../../../Table/Table.proptype';
import { hideElementForRoles } from '../../../UserRoleStylesProvider/constants';
import { CreditRow, getCreditRows } from '../../creditUtils';
import useClickOutsideDialog from '../../hooks/useClickOutsideDialog';
import {
  EntityNumberRouteMatch,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails,
  isCreditNameInFinancials
} from '../../utils';
import BalanceCalculationsModal from '../BalanceCalculationsModal';
import InterlinkedDataDialog from '../InterlinkedDataDialog';
import RtpColumnIcons from '../RtpColumnIcons';

const LEVEL: Level = LEVELS.FEDERAL;
const STEP: Step = 'credits';
const STEPS: Step[] = [STEP, 'rtp'];
const LevelAndStep = `${LEVEL}.${STEP}`;

const FederalCredits = ({ entityId }: FederalTabProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [creditNameForModal, setCreditNameForModal] = useState('');
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const [rows, setRows] = useState<CreditRow[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const [hasNewCredit, setHasNewCredit] = useState(false);
  const [creditNameOfNewRow, setCreditNameOfNewRow] = useState('');
  const [newCreditName, setNewCreditName] = useState('');
  const [isCreditNameDuplicated, setIsCreditNameDuplicated] = useState<boolean>(false);
  const [isCreditNameReservedName, setIsCreditNameReservedName] = useState<boolean>(false);
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LevelAndStep, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS);
  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const rtpFinancialInfo = useMemo(() => tabsData['federal.rtp'] ?? [], [tabsData]);
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const anchorEl = tableRef.current;
  const {
    prov2818InterlinkData,
    prov2826TarfEnhancements,
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3736ReservedWordValidation: showReservedWordsError,
    prov4011TotalRowFix,
    prov4438FilterAdjustmentsByRowId: filterAdjustmentsByRowId
  } = useFlags();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await HTTPService.request<LambdaResponse<any>>({
          method: 'get',
          apiUrlKey: 'apiUrl',
          relativePath: '/v1/container-rtp-financials'
        });
        dispatch(setInterlinkedDataWarningEntities(data));
      } catch (error: unknown) {
        console.error('Error fetching container data', error);
      }
    };

    void fetchData();
  }, [dispatch, financialInfo]);

  const getRtpDifference = useCallback(
    (creditName: string) => {
      const creditsTaxReturnRecord = rtpFinancialInfo.find(
        (record) => record.columnName === 'taxReturn' && record.rowName === creditName
      );
      const creditsTaxProvisionRecord = rtpFinancialInfo.find(
        (record) => record.columnName === 'taxProvision' && record.rowName === creditName
      );
      const creditsTaxReturnValue = creditsTaxReturnRecord ? Number(creditsTaxReturnRecord.value) : 0;
      const creditsTaxProvisionValue = creditsTaxProvisionRecord ? Number(creditsTaxProvisionRecord.value) : 0;
      const rtpCreditsDifference = creditsTaxReturnValue - creditsTaxProvisionValue;
      return rtpCreditsDifference;
    },
    [rtpFinancialInfo]
  );

  const shouldShowRtpIcon = prov2818InterlinkData;

  useEffect(() => {
    if (!isBalanceModalOpen) setCreditNameForModal('');
  }, [isBalanceModalOpen]);

  useClickOutsideDialog(dialogRef, setIsDialogOpen);

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: t('Tax Period'),
        isNewRowEditable: true,
        className: 'creditTaxPeriodColumn'
      },
      {
        field: 'beginningBalance',
        headerName: t('Beginning Balance'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      {
        field: 'rtp',
        headerName: t('Return to Provision'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true,
        shouldShowRtpIcon,
        renderCell: (
          row: Row,
          value: number,
          column: Column,
          failedCells: FailedCells,
          onCellChange: TableProps['onCellChange'],
          onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
          renderOpts: any,
          rows: Row[],
          onCellClick?: TableProps['onCellClick']
          // eslint-disable-next-line max-params
        ) => {
          return shouldShowRtpIcon ? (
            <RtpColumnIcons
              {...{
                ignoreRenderCell: true,
                isTotalRow: row.total === true || row.isTotal === true,
                disableButton: hasNewRow,
                value,
                column,
                failedCells,
                renderOpts,
                rows,
                row,
                totalDifference: getRtpDifference(row.isTotal ? row.credits[0].creditName : undefined),
                onCellChange,
                onCellOrCommentBlur,
                onCellClick,
                handleIconButtonClick: () => {
                  setCreditNameForModal(row?.credits[0].creditName);
                  setIsDialogOpen((prevIsDialogOpen) => !prevIsDialogOpen);
                }
              }}
            />
          ) : (
            renderCell(
              row,
              value,
              column,
              failedCells,
              onCellChange,
              onCellOrCommentBlur,
              renderOpts,
              rows,
              '',
              onCellClick,
              true
            )
          );
        }
      },
      {
        field: 'generatedAmount',
        headerName: t('Amount Generated in Current Year'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      {
        field: 'usedAmount',
        headerName: t('Amount (Used) in Current Year'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      {
        field: 'deferredOnlyAdjustment',
        headerName: t('Deferred Only Adjustment'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      ...(prov2826TarfEnhancements
        ? [
            {
              field: 'oci',
              headerName: t('OCI'),
              isEditable: !stepCompletionStatus.status,
              isNumber: true
            },
            {
              field: 'goodwill',
              headerName: t('Goodwill'),
              isEditable: !stepCompletionStatus.status,
              isNumber: true
            },
            {
              field: 'fin48',
              headerName: t('FIN48'),
              isEditable: !stepCompletionStatus.status,
              isNumber: true
            }
          ]
        : []),
      {
        field: 'balanceSheetOnlyAdjustment',
        headerName: t('Balance Sheet Only Adjustment'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      {
        field: 'endingBalance',
        headerName: t('Ending Balance'),
        getValue: (row: any) =>
          'endingBalance' in row ? row.endingBalance : calculateEndingBalanceForCredits(row).endingBalance,
        isNumber: true
      },
      {
        field: 'remaining',
        headerName: t('Carry Over Period Remaining'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      }
    ];
  }, [getRtpDifference, hasNewRow, prov2826TarfEnhancements, shouldShowRtpIcon, stepCompletionStatus.status, t]);

  useEffect(() => {
    setIsCreditNameDuplicated(isCreditNameInFinancials(financialInfo, newCreditName));
  }, [financialInfo, newCreditName]);

  useEffect(() => {
    setIsCreditNameReservedName(showReservedWordsError && reservedNames.includes(newCreditName.toLowerCase()));
  }, [newCreditName, showReservedWordsError]);

  useEffect(() => {
    if (creditNameOfNewRow) {
      const dataForMethods = {
        columns,
        dispatch,
        entityId,
        financialInfo,
        hasNewRow,
        level: LEVEL,
        rows,
        setHasNewRow,
        setRows,
        step: STEP,
        t,
        showReservedWordsError,
        filterAdjustmentsByRowId
      };
      handleNewRowForEntityDetails({
        ...dataForMethods,
        creditName: creditNameOfNewRow
      });
      setCreditNameOfNewRow('');
    }
  }, [
    columns,
    dispatch,
    entityId,
    financialInfo,
    hasNewRow,
    rows,
    setRows,
    creditNameOfNewRow,
    t,
    showReservedWordsError,
    filterAdjustmentsByRowId
  ]);

  const newRow = rows.find((row) => row.isNew);
  useEffect(() => {
    const dataForRows = {
      financialInfo,
      newRow,
      hasNewCredit,
      isCompleted: stepCompletionStatus.status,
      onNewRowClicked: setCreditNameOfNewRow,
      setNewCreditName,
      t,
      isCreditNameDuplicated,
      isCreditNameReservedName,
      newCreditName,
      prov4011TotalRowFix,
      filterAdjustmentsByRowId,
      level: LEVEL,
      step: STEP,
      tabsData
    };
    const rowsToSet = getCreditRows(dataForRows);
    setRows(rowsToSet);
  }, [
    financialInfo,
    stepCompletionStatus.status,
    newRow,
    hasNewCredit,
    t,
    isCreditNameDuplicated,
    isCreditNameReservedName,
    newCreditName,
    prov4011TotalRowFix,
    filterAdjustmentsByRowId,
    tabsData
  ]);

  useEffect(() => {
    if (!hasNewRow) {
      setHasNewCredit(false);
      setNewCreditName('');
    }
  }, [hasNewRow]);

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo,
    hasNewRow,
    level: LEVEL,
    rows,
    setHasNewRow,
    setRows,
    step: STEP,
    t,
    showReservedWordsError
  };

  const handleAddNewCredit = () => {
    setHasNewCredit(true);
    handleNewRowForEntityDetails({
      ...dataForMethods
    });
  };

  const handleSaveNewCredit = () => {
    if (newCreditName.length > 0) {
      const newRows = rows.map((row) => ({ ...row, creditName: row.isNew ? newCreditName : row.creditName }));
      handleNewRowForEntityDetails({
        ...dataForMethods,
        rows: newRows
      });
      const addedRow = rows.find((row) => row.isNew);
      if (showReservedWordsError && reservedNames.includes(String(addedRow?.name?.toLowerCase()))) return;

      setHasNewCredit(false);
      setNewCreditName('');
    }
  };

  const shouldHideNewRowButton = (hasNewRow && !hasNewCredit) || stepCompletionStatus.status;

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <LoadingWrapper isLoading={isFetchLoading}>
        <ContentAndTitle
          title={
            <TabTitle
              currencyIsoCode={currencyIsoCode}
              title={t('credits')}
              isCompleted={stepCompletionStatus.status}
              onCompletionChange={(checked) => {
                dispatch(
                  setEntityCompletionStatus({
                    ...stepCompletionStatus,
                    newStatus: checked
                  })
                );
              }}
            />
          }
        >
          <>
            <div ref={tableRef} style={{ overflow: 'auto' }}>
              <TableWithComment
                columns={columns}
                rows={rows}
                hideActionsMenu={stepCompletionStatus.status}
                failedCells={failedCells}
                renderOpts={{ placeholder: 'Enter Tax Period' }}
                handleOnRowDelete={(params) => {
                  handleOnRowDeleteForEntityDetails({
                    ...dataForMethods,
                    ...params
                  });
                }}
                onCellChange={(params) => {
                  if (!isPerformanceFixEnabled) {
                    handleEditRowForEntityDetails({
                      ...dataForMethods,
                      ...params
                    });
                  }
                }}
                onCellOrCommentBlur={(params) => {
                  if (isPerformanceFixEnabled) {
                    handleEditRowForEntityDetails({
                      ...dataForMethods,
                      ...params
                    });
                  }

                  handleOnCellOrCommentBlurForEntityDetails({
                    ...dataForMethods,
                    ...params
                  });
                }}
              />
            </div>
            <Box mt={2}>
              {shouldHideNewRowButton ? null : (
                <Button
                  data-roles-hide-element={hideElementForRoles.join(' ')}
                  variant="outlined"
                  disabled={isCreditNameDuplicated || isCreditNameReservedName}
                  onClick={hasNewCredit ? handleSaveNewCredit : handleAddNewCredit}
                >
                  {t(hasNewCredit ? 'Save New Credit' : 'Add New Credit')}
                </Button>
              )}
            </Box>
          </>
        </ContentAndTitle>
      </LoadingWrapper>
      <InterlinkedDataDialog
        ref={dialogRef}
        open={isDialogOpen}
        handleClose={handleClose}
        anchorEl={anchorEl}
        icon={<NotEqualIcon />}
        value={getRtpDifference(creditNameForModal) ?? 0}
        level={LEVEL}
        step={STEP}
        creditName={creditNameForModal}
        onBalanceClick={() => {
          setIsBalanceModalOpen(true);
          return null;
        }}
        onViewClick={() => {
          history.push(`/entity-details/${entityNumber}/federal/rtp`);
          return null;
        }}
      />
      <BalanceCalculationsModal
        currentStep={STEP}
        level={LEVEL}
        steps={STEPS}
        entityId={entityId}
        open={isBalanceModalOpen}
        setOpen={setIsBalanceModalOpen}
        entityNumber={entityNumber}
        total={getRtpDifference(creditNameForModal) ?? 0}
        creditName={creditNameForModal}
      />
    </>
  );
};

export default FederalCredits;

import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContentAndTitle, TabTitle } from '.';

import { TableWithComment } from '../..';
import { calculatePermanent } from '../../../calculations';
import { FEDERAL_UUID, LEVELS } from '../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../hooks';
import { FederalTabProps, Level, Step } from '../../../models';
import { setEntityCompletionStatus } from '../../../redux/entitiesCompletionStatus';
import LoadingWrapper from '../../LoadingWrapper';
import { Row } from '../../Table/Table.proptype';
import {
  EntityNumberRouteMatch,
  getPermanentColumns,
  getPermanentKeyColumns,
  getTableDataByRowIdFinancialData,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails,
  PermanentAdjustmentShape
} from '../utils';

export type PermanentAdjustmentsRows = Row & PermanentAdjustmentShape;

const LEVEL: Level = LEVELS.FEDERAL;
const STEP: Step = 'permanent';
const LEVEL_AND_STEP = `${LEVEL}.${STEP}`;
const STEPS: Step[] = [STEP, 'rtp'];

const PermanentAdjustments = ({ entityId }: FederalTabProps) => {
  const { t } = useTranslation();
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const [rows, setRows] = useState<PermanentAdjustmentsRows[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const dispatch = useDispatch();
  const {
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3736ReservedWordValidation: showReservedWordsError,
    prov4011TotalRowFix,
    prov4438FilterAdjustmentsByRowId: filterAdjustmentsByRowId
  } = useFlags();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LEVEL_AND_STEP, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS);
  const permAdjFinancialInfo = useMemo(() => tabsData[LEVEL_AND_STEP] ?? [], [tabsData]);
  const rtpFinancialInfo = useMemo(() => tabsData['federal.rtp'] ?? [], [tabsData]);
  const mergedFinancialInfo = useMemo(() => [...permAdjFinancialInfo, ...rtpFinancialInfo], [
    permAdjFinancialInfo,
    rtpFinancialInfo
  ]);

  const rowsWithData = useMemo(() => {
    return getTableDataByRowIdFinancialData(
      LEVEL,
      STEP,
      permAdjFinancialInfo,
      filterAdjustmentsByRowId,
      tabsData
    ) as PermanentAdjustmentsRows[];
  }, [permAdjFinancialInfo, tabsData, filterAdjustmentsByRowId]);

  useEffect(() => {
    setRows(rowsWithData.map((row) => calculatePermanent(row)));
  }, [rowsWithData]);

  const columns = [
    ...getPermanentKeyColumns(t),
    ...getPermanentColumns(t, prov4011TotalRowFix, !stepCompletionStatus.status)
  ];

  const dataForMethods = {
    calculateFunc: calculatePermanent,
    columns,
    dispatch,
    entityId,
    financialInfo: mergedFinancialInfo,
    hasNewRow,
    level: LEVEL,
    rows,
    setHasNewRow,
    setRows,
    step: STEP,
    t,
    showReservedWordsError,
    filterAdjustmentsByRowId
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('Permanent Adjustments')}
            isCompleted={stepCompletionStatus.status}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <TableWithComment
          columns={columns}
          failedCells={failedCells}
          newRowButtonLabel={
            stepCompletionStatus.status ? null : t(hasNewRow ? 'Save Adjustment' : 'Add Permanent Adjustment')
          }
          rows={rows}
          totalHeaderName={t('Total')}
          hideActionsMenu={stepCompletionStatus.status}
          handleOnRowDelete={(params) => {
            handleOnRowDeleteForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
          onCellChange={(params) => {
            if (!isPerformanceFixEnabled) {
              handleEditRowForEntityDetails({
                ...dataForMethods,
                ...params
              });
            }
          }}
          onCellOrCommentBlur={(params) => {
            if (isPerformanceFixEnabled) {
              handleEditRowForEntityDetails({
                ...dataForMethods,
                ...params
              });
            }

            handleOnCellOrCommentBlurForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
          onNewRowClick={() => {
            handleNewRowForEntityDetails({
              ...dataForMethods
            });
          }}
        />
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default PermanentAdjustments;

import { useMemo, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useStateDeferredRowsWithData } from '.';

import {
  getFullStateData,
  getValuationAllowance,
  groupByJurisdictionId,
  makeRows,
  useEntityTaxRatesByJurisdictionId
} from './utils';

import { ContentAndTitle, TabTitle } from '..';
import { TableWithComment } from '../../..';
import { FEDERAL_UUID, LEVELS, TABS_WITH_IMPORT_BUTTON_AT_TOP } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { Entity, Step, SubJurisdiction, SubJurisdictionById, Row } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import { selectDoesUserHaveRole } from '../../../../selectors';
import LoadingWrapper from '../../../LoadingWrapper';
import { Column, FailedCells, TableProps } from '../../../Table/Table.proptype';
import { renderValue } from '../../../Table/utils';
import { ProReadOnly, ProReviewer } from '../../../UserRoleStylesProvider/constants';
import {
  getDeferredColumns,
  EntityNumberRouteMatch,
  handleOnCellOrCommentBlurForEntityDetails,
  getDeferredColumnsFlagged
} from '../../utils';
import { getLinkButtonCssProps } from '../../utils/styles';
import { MODIFICATIONS_TEMPORARY } from '../StateModifications/constants';

type Props = {
  entityId: Entity['entityId'];
  states?: SubJurisdiction[];
  subJurisdictionById?: SubJurisdictionById;
};

const useStyles = makeStyles((theme) => ({
  linkButton: ({ isUserReadOnly }: { isUserReadOnly: boolean }) => ({
    ...getLinkButtonCssProps(theme, isUserReadOnly),
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'inherit'
    }
  })
}));

const TAB_LEVEL_AND_STEP = 'state.deferred';

const StateDeferred = ({ entityId, states = [], subJurisdictionById = new Map() }: Props) => {
  const { t } = useTranslation();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, TAB_LEVEL_AND_STEP, FEDERAL_UUID);
  const { prov2826TarfEnhancements, prov4438FilterAdjustmentsByRowId: filterAdjustmentsByRowId } = useFlags();
  const isTarfFlagActive = prov2826TarfEnhancements;
  const isUserReadOnly = useSelector(selectDoesUserHaveRole([ProReadOnly.Name, ProReviewer.Name]));
  const dispatch = useDispatch();
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVELS.FEDERAL, [
    'deferred',
    'temporary',
    'rtp',
    'credits',
    'permanent'
  ]);
  const renderAdjNameWithAccNumCell = useCallback(
    (
      row: Row,
      value: number,
      column: Column,
      failedCells: FailedCells,
      onCellChange: TableProps['onCellChange'],
      onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
      onCellClickFiller: TableProps['onCellClick'], // Here because there are problems around shared render functions
      renderOpts: any,
      rows: Row[],
      onCellClick?: TableProps['onCellClick']
      // eslint-disable-next-line max-params
    ) => {
      const adjNameWithAccountNumber = (accountNumber: string) => {
        return (
          <>
            <div
              style={{
                color: 'rgb(153, 164, 181)',
                fontSize: '12px',
                fontStyle: 'italic'
              }}
            >
              {accountNumber}
            </div>
            {row?.renderCell
              ? row.renderCell(
                  row,
                  value,
                  column,
                  failedCells,
                  onCellChange,
                  onCellOrCommentBlur,
                  onCellClick,
                  renderOpts,
                  rows,
                  '',
                  onCellClick
                )
              : renderValue(value, column, renderOpts, row, '')}
          </>
        );
      };

      const isValidAccountNumber = row?.accountNumber && filterAdjustmentsByRowId;

      if (
        row.creditName === 'state.modifications.temporary' &&
        tabsData?.['federal.temporary.balanceSheet'] &&
        tabsData?.['federal.temporary.incomeStatement']
      ) {
        const fedTempRecord = [
          ...tabsData['federal.temporary.balanceSheet'],
          ...tabsData['federal.temporary.incomeStatement']
        ].find((source) => source.rowId === row.sourceRowId);
        row.accountNumber = fedTempRecord?.accountNumber ?? undefined;
      }

      if (isValidAccountNumber) {
        return adjNameWithAccountNumber(row.accountNumber);
      }

      if (row?.renderCell) {
        return row.renderCell(
          row,
          value,
          column,
          failedCells,
          onCellChange,
          onCellOrCommentBlur,
          onCellClick,
          renderOpts,
          rows,
          '',
          onCellClick
        );
      }

      return renderValue(value, column, renderOpts, row, '');
    },
    [filterAdjustmentsByRowId, tabsData]
  );
  const columns = useMemo(
    () =>
      isTarfFlagActive
        ? getDeferredColumnsFlagged(t, stepCompletionStatus.status || isUserReadOnly, renderAdjNameWithAccNumCell)
        : getDeferredColumns(t, stepCompletionStatus.status || isUserReadOnly, renderAdjNameWithAccNumCell),
    [isTarfFlagActive, isUserReadOnly, stepCompletionStatus.status, t, renderAdjNameWithAccNumCell]
  );
  useFinancialData(entityNumber, 'state', [
    'apportionment',
    'deferred',
    'nol',
    'credits',
    MODIFICATIONS_TEMPORARY,
    'rtp'
  ]);
  const taxRates = useEntityTaxRatesByJurisdictionId(entityId);
  const classes = useStyles({ isUserReadOnly });

  const {
    balanceSheetRowsWithData,
    concatenatedCellData,
    creditsRowsWithData,
    deferredRowsWithData,
    federalDeferredRowsWithData,
    incomeStatementRowsWithData,
    modificationsRowsWithData,
    rtpRowsWithData,
    stateRtpRowsWithData,
    stateApportionmentRowsWithData
  } = useStateDeferredRowsWithData(tabsData, filterAdjustmentsByRowId);

  const creditsRowsGroupedByJurisdiction = groupByJurisdictionId(creditsRowsWithData);

  const fullStateData = getFullStateData({ states, tabsData });

  const valuationAllowance = getValuationAllowance(deferredRowsWithData);

  const rows = makeRows(
    t,
    columns,
    {
      states: fullStateData,
      deferredRowsWithData,
      federalDeferredRowsWithData,
      modificationsRowsWithData,
      subJurisdictionById,
      creditsRowsGroupedByJurisdiction,
      stateRtpRowsWithData,
      stateApportionmentRowsWithData,
      tabsData,
      valuationAllowance,
      balanceSheet: balanceSheetRowsWithData,
      incomeStatement: incomeStatementRowsWithData,
      rtp: rtpRowsWithData,
      taxRates,
      dispatch,
      className: classes.linkButton
    },
    stepCompletionStatus.status,
    filterAdjustmentsByRowId
  );

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo: concatenatedCellData,
    level: LEVELS.STATE,
    rows,
    step: 'deferred' as Step,
    t,
    filterAdjustmentsByRowId
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('Deferred Rollforward')}
            isCompleted={stepCompletionStatus.status}
            shouldDisplayDataImportButton={TABS_WITH_IMPORT_BUTTON_AT_TOP.state.rtp}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <TableWithComment
          columns={columns}
          rows={rows}
          failedCells={failedCells}
          hideActionsMenu={stepCompletionStatus.status}
          onCellOrCommentBlur={(params) => {
            const isDynamicAdj =
              filterAdjustmentsByRowId &&
              [
                'federal.temporary.incomeStatement',
                'federal.temporary.balanceSheet',
                'state.modifications.temporary'
              ].includes(params.row?.creditName) &&
              params.row.name !== 'Valuation Allowance';
            if (!params.row.sourceRowId && isDynamicAdj) {
              params.row.sourceRowId = params.row.rowId;
              params.row.rowId = undefined;
            }

            handleOnCellOrCommentBlurForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
        />
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default StateDeferred;

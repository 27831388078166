import { TFunction } from 'react-i18next';

import { calculateReturnToProvisionSum, calculateRTPProduct } from '../../../../calculations';
import { ATTRIBUTE_NAMES } from '../../../../constants';
import { sortOnColumn } from '../../../../utils';
import { Column, Row } from '../../../Table/Table.proptype';
import {
  makeSectionTitle,
  emptySectionHeader,
  RowsForCreateSourceObject,
  renderRTPPercentageCell,
  makeSectionTitleWithCreditName
} from '../../utils';

export const SUSTAINED_RTP_ROWS: RowsForCreateSourceObject = {
  federalTaxableIncomeBeforeStateTaxAndNOL: {
    name: 'Federal Taxable Income/(Loss) Before State Tax and NOL',
    isStatic: true
  },
  preApportionedNetOperatingLoss: { name: 'Pre-Apportioned Net Operating Loss', isStatic: true },
  preApportionedStateTaxableIncome: { name: 'Pre-Apportioned State Taxable Income', isStatic: true },
  apportionmentFactor: { name: 'Apportionment Factor', isStatic: true },
  postApportionedStateTaxableIncomeBeforeNOL: {
    name: 'Post-Apportioned State Taxable Income/(NOL) Before NOL',
    isStatic: true
  },
  postApportionedStateTaxableIncomeAfterNOL: { name: 'Post-Apportioned Net Operating Loss', isStatic: true },
  postApportionedStateTaxableIncome: { name: 'Post-Apportioned State Taxable Income/(NOL)', isStatic: true },
  priorYearStateTaxRate: { name: ATTRIBUTE_NAMES.priorYearStateTaxRate, isStatic: true },
  stateTaxExpenseBeforeCredits: { name: 'State Tax Expense/(Benefit) Before Credits', isStatic: true },
  stateTaxExpenseAfterCredits: { name: 'State Tax Expense/(Benefit) After Credits', isStatic: true },
  stateTaxExpense: { name: 'State Tax Expense/(Benefit)', isStatic: true }
};

type MakeRowsProps = {
  t: TFunction;
  columns: Column[];
  source: any;
  dispatch?: any;
  className?: string;
  stepCompletionStatus?: boolean;
};

const makeRows = ({ t, columns, source, dispatch, className, stepCompletionStatus }: MakeRowsProps) => {
  if (!source) {
    return [];
  }

  const totalPermanent = calculateReturnToProvisionSum({ data: source.permanent });
  const totalTemporary = calculateReturnToProvisionSum({ data: source.temporary });
  const totalTaxEffected = calculateReturnToProvisionSum({ data: source.taxEffected });
  const totalCredits = calculateReturnToProvisionSum({ data: source.credits });
  const preApportionedStateTaxableIncome = calculateReturnToProvisionSum({
    data: [
      source.federalTaxableIncomeBeforeStateTaxAndNOL,
      totalPermanent,
      totalTemporary,
      source.preApportionedNetOperatingLoss
    ]
  });
  const postApportionedStateTaxableIncomeBeforeNOL = calculateRTPProduct(
    preApportionedStateTaxableIncome,
    source.apportionmentFactor
  );
  const postApportionedStateTaxableIncome = calculateReturnToProvisionSum({
    data: [postApportionedStateTaxableIncomeBeforeNOL, source.postApportionedStateTaxableIncomeAfterNOL]
  });
  const taxBeforeCredits = calculateRTPProduct(postApportionedStateTaxableIncome, source.priorYearStateTaxRate);
  const stateTaxExpenseAfterCredits = calculateReturnToProvisionSum({ data: [taxBeforeCredits, totalCredits] });
  const stateTaxExpense = calculateReturnToProvisionSum({ data: [stateTaxExpenseAfterCredits, totalTaxEffected] });

  const rows: Row[] = [
    {
      ...source.federalTaxableIncomeBeforeStateTaxAndNOL,
      nonEditableFields: ['taxProvision', 'taxReturn']
    },
    makeSectionTitleWithCreditName({
      title: t('Permanent Modifications'),
      options: { withImportLink: true, stepCompletionStatus },
      dispatch,
      className,
      creditName: 'state.modifications.permanent'
    }),
    ...sortOnColumn(source.permanent, columns[0]),
    {
      isTotal: true,
      name: t('Total Permanent Modifications'),
      ...totalPermanent
    },

    makeSectionTitleWithCreditName({
      title: t('Temporary Modifications'),
      options: { withImportLink: true, stepCompletionStatus },
      dispatch,
      className,
      creditName: 'state.modifications.temporary'
    }),
    ...sortOnColumn(source.temporary, columns[0]),
    {
      isTotal: true,
      name: t('Total Temporary Modifications'),
      ...totalTemporary
    },
    emptySectionHeader,
    source.preApportionedNetOperatingLoss,
    emptySectionHeader,
    {
      ...source.preApportionedStateTaxableIncome,
      ...preApportionedStateTaxableIncome,
      nonEditableFields: ['taxProvision', 'taxReturn'],
      categorizable: false
    },
    emptySectionHeader,
    { ...source.apportionmentFactor, renderCell: renderRTPPercentageCell, categorizable: false },
    emptySectionHeader,
    {
      ...source.postApportionedStateTaxableIncomeBeforeNOL,
      ...postApportionedStateTaxableIncomeBeforeNOL,
      nonEditableFields: ['taxProvision', 'taxReturn'],
      categorizable: false
    },
    emptySectionHeader,
    source.postApportionedStateTaxableIncomeAfterNOL,
    emptySectionHeader,
    {
      ...source.postApportionedStateTaxableIncome,
      ...postApportionedStateTaxableIncome,
      nonEditableFields: ['taxProvision', 'taxReturn'],
      categorizable: false
    },
    emptySectionHeader,
    {
      ...source.priorYearStateTaxRate,
      renderCell: renderRTPPercentageCell,
      categorizable: false
    },

    emptySectionHeader,
    {
      ...source.stateTaxExpenseBeforeCredits,
      ...taxBeforeCredits,
      nonEditableFields: ['taxProvision', 'taxReturn'],
      categorizable: false
    },
    makeSectionTitle(t('Credits')),
    ...sortOnColumn(source.credits, columns[0]),
    { isTotal: true, name: t('Total Credits'), ...totalCredits, categorizable: false },
    emptySectionHeader,
    {
      ...source.stateTaxExpenseAfterCredits,
      ...stateTaxExpenseAfterCredits,
      nonEditableFields: ['taxProvision', 'taxReturn'],
      categorizable: false
    },
    makeSectionTitle(t('Tax-Effected Adjustments')),
    ...sortOnColumn(source.taxEffected, columns[0]),
    { isTotal: true, name: t('Total Tax-Effected Adjustments'), ...totalTaxEffected, categorizable: false },
    emptySectionHeader,
    {
      isTotal: true,
      ...source.stateTaxExpense,
      ...stateTaxExpense,
      categorizable: false
    }
  ];

  return rows;
};

export default makeRows;

import { useInView } from 'react-intersection-observer';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

interface InViewObserverWrapperTableProps {
  children: any;
  dataTestId?: string;
  rowStyle?: string;
  hover?: boolean;
}

const InViewObserverWrapperTable = ({
  children,
  dataTestId = '',
  rowStyle = '',
  hover = false
}: InViewObserverWrapperTableProps) => {
  const { ref, inView } = useInView({
    threshold: 0.02,
    rootMargin: '200px 0px',
    trackVisibility: true,
    delay: 100,
    triggerOnce: true
  });

  const rowName = children[0]?.props?.['data-meta-rowname'] ?? 'Loading...';

  return (
    <TableRow ref={ref} data-testid={dataTestId} className={rowStyle} hover={hover} style={{ opacity: inView ? 1 : 0 }}>
      {inView ? (
        children
      ) : (
        <TableCell className="no-rows" colSpan={5}>
          {rowName}
        </TableCell>
      )}
    </TableRow>
  );
};

export default InViewObserverWrapperTable;

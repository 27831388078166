import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Divider, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { reservedNames } from '../../../../../../constants';
import { FinancialInfoTabsData } from '../../../../../../models';
import { getStepBasedNameDropdownOptions } from '../../helpers';
import { StateModificationsRow } from '../../StateModifications.types';
import AddCustomModification from '../AddCustomModification';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%'
  },
  divider: {
    margin: theme.spacing(0.5, 0),
    backgroundColor: theme.palette.primary.dark
  }
}));

interface Props {
  row: StateModificationsRow;
  tabsData: FinancialInfoTabsData;
  rows?: StateModificationsRow[];
  showReservedWordsError?: boolean;
  filterAdjustmentsByRowId?: boolean;
  onChange: (props: any) => void;
}

interface SelectorOption {
  name: string;
  value: string;
  disabled: boolean;
}

const AdjustmentNameSelector = ({
  row,
  rows,
  tabsData,
  showReservedWordsError,
  filterAdjustmentsByRowId,
  onChange
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string>('');
  const [selectedCustomModification, setSelectedCustomModification] = useState<string>('');
  const [modificationOptions, setModificationOptions] = useState<SelectorOption[]>([]);
  const [selectorKey, setSelectorKey] = useState(new Date().toISOString());
  const [reservedWordsError, setReservedWordsError] = useState<string>('');

  useEffect(() => {
    const existingModifications = getStepBasedNameDropdownOptions({ row, tabsData, rows, filterAdjustmentsByRowId });
    setModificationOptions(existingModifications || []);
    if (
      selectedCustomModification &&
      existingModifications.some((mod: any) => mod.name === selectedCustomModification)
    ) {
      setSelected(selectedCustomModification);
      setSelectedCustomModification('');
    }
  }, [row, tabsData, rows, selectedCustomModification, filterAdjustmentsByRowId]);

  function handleChange({ target: { value } }: any): void {
    const selectedObj: any = modificationOptions.find((opt) => opt.value === value);
    row.sourceRowId = selectedObj?.rowId ?? '';
    row.rowId = selectedObj?.rowId ?? '';
    setReservedWordsError('');
    setSelected(value);
    onChange({
      value: selectedObj.name,
      row
    });
  }

  function handleNewCustomModification(newCustomModification: string) {
    if (reservedNames.includes(newCustomModification.toLowerCase()) && showReservedWordsError) {
      setReservedWordsError(`'${newCustomModification}' is a reserved word. Please choose another name.`);
    } else {
      setReservedWordsError('');
    }

    setModificationOptions((modificationOptions) => [
      ...modificationOptions,
      { name: newCustomModification, value: newCustomModification, disabled: false }
    ]);
    setSelectedCustomModification(newCustomModification);
    onChange({
      value: newCustomModification,
      row
    });
    setSelectorKey(new Date().toISOString());
  }

  return (
    <TextField
      key={selectorKey}
      select
      label={(row.duplicateName && t('Name already exists')) || t(reservedWordsError)}
      error={row.duplicateName || Boolean(reservedWordsError)}
      variant="outlined"
      value={selected}
      className={classes.select}
      SelectProps={{
        displayEmpty: true
      }}
      onChange={handleChange}
    >
      <MenuItem disabled value="">
        {t('Adjustment Name')}
      </MenuItem>
      {modificationOptions.map((obj: any) => (
        <MenuItem key={obj.rowId} disabled={obj.disabled} selected={selected === obj.value} value={obj.value}>
          {obj.name}
          {obj?.accountNumber && filterAdjustmentsByRowId ? ` - ${String(obj.accountNumber)}` : null}
        </MenuItem>
      ))}
      <Divider className={classes.divider} />
      <AddCustomModification onButtonClicked={handleNewCustomModification} />
    </TextField>
  );
};

export default AdjustmentNameSelector;

import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MuiTableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Add from '@material-ui/icons/Add';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { EditableNumericCell, EditableTextualCell } from '.';

import { selectIsUploadLoadingFinancialData } from '../../../selectors';
import { selectDoesUserHaveRole } from '../../../selectors/authentication';
import InViewObserverWrapperTable from '../../InViewObserverWrapperTable';
import { ProReadOnly, ProReviewer } from '../../UserRoleStylesProvider/constants';
import { Column, EditableCellProps, FailedCells, Row, TableBodyProps, TableProps } from '../Table.proptype';
import { getCell, getAlignment, getClassName, renderValue, OUTLINED } from '../utils';

function makeNewRowButton({
  label,
  handleClick,
  columns,
  isUploadLoading,
  newRow
}: {
  label: any;
  handleClick: any;
  columns: any;
  isUploadLoading: boolean;
  newRow?: Row;
}) {
  return (
    <TableCell colSpan={columns.length}>
      <Button
        variant="text"
        endIcon={<Add fontSize="small" />}
        disabled={isUploadLoading}
        onClick={() => handleClick?.(newRow)}
      >
        {label}
      </Button>
    </TableCell>
  );
}

const getIsNumberOrPercentage = (column: Column, row?: Row) =>
  column.isNumber ?? column.isPercentage ?? row?.percentageFields?.includes(column.field ?? '');

export const EditableCell = (props: EditableCellProps) => {
  const { editableCellClassName, column, row } = props;

  const isNumberOrPercentage = getIsNumberOrPercentage(column, row);
  return isNumberOrPercentage ? (
    <EditableNumericCell {...props} />
  ) : (
    <EditableTextualCell {...props} editableCellClassName={editableCellClassName} />
  );
};

// eslint-disable-next-line max-params
export function renderCell(
  row: Row,
  value: number,
  column: Column,
  failedCells: FailedCells,
  onCellChange: TableProps['onCellChange'],
  onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
  renderOpts: any,
  rows: Row[],
  reportName?: string,
  onCellClick?: TableProps['onCellClick'],
  ignoreRenderCell?: boolean
) {
  // TODO: reduce complexity here
  if (column.renderCell && !ignoreRenderCell) {
    return column.renderCell(
      row,
      value,
      column,
      failedCells,
      onCellChange,
      onCellOrCommentBlur,
      onCellClick,
      renderOpts,
      rows,
      reportName,
      onCellClick,
      ignoreRenderCell
    );
  }

  if (row.renderCell && !ignoreRenderCell) {
    return row.renderCell(
      row,
      value,
      column,
      failedCells,
      onCellChange,
      onCellOrCommentBlur,
      onCellClick,
      renderOpts,
      rows,
      reportName,
      onCellClick,
      ignoreRenderCell
    );
  }

  const rowsArray = Array.isArray(rows) ? rows : [rows];
  const hasNewRow = rowsArray?.some((row) => row?.isNew);
  if (
    (column.isEditable || (column.isNewRowEditable && row.isNew) || (row.isEditable && column.field !== 'name')) &&
    // @ts-expect-error TODO - we need to figure out if column field and row name can really be undefined
    !row.nonEditableFields?.includes(column.field) &&
    (Boolean(onCellChange) || Boolean(onCellOrCommentBlur)) &&
    !row.isTotal &&
    (!rows || row.isNew || !hasNewRow)
  ) {
    return (
      <EditableCell
        column={column}
        row={row}
        failedCells={failedCells}
        renderOpts={renderOpts}
        onChange={onCellChange}
        onCellOrCommentBlur={onCellOrCommentBlur}
      />
    );
  }

  return renderValue(value, column, renderOpts, row, reportName);
}

const TableBodyWithInView = ({
  classes,
  columns,
  rows,
  selected,
  noRowLabel,
  totalHeaderName,
  variant,
  isSelectable,
  selectorsDisabled,
  newRowButtonLabel,
  activeRow,
  activeColumn,
  renderOpts,
  failedCells = {},
  reportName,
  onSelectChange,
  onNewRowClick,
  onCellChange,
  onCellOrCommentBlur,
  onCellClick
}: TableBodyProps) => {
  const { prov4276DisableSaveButtonOnSave } = useFlags();
  const isUserReadOnly = useSelector(selectDoesUserHaveRole([ProReadOnly.Name, ProReviewer.Name]));
  const isUploadLoading = Boolean(useSelector(selectIsUploadLoadingFinancialData) && prov4276DisableSaveButtonOnSave);
  // TODO - see if complexity can be reduced
  // eslint-disable-next-line complexity
  const body = useMemo(() => {
    if (!Array.isArray(columns) || !Array.isArray(rows)) {
      return [];
    }

    const newRow = rows.find((row) => row.isNew);
    if (rows.length === 0) {
      return [
        <InViewObserverWrapperTable key="no-row-container-1" dataTestId="table-no-row">
          <TableCell className="no-rows" colSpan={columns.length}>
            {noRowLabel}
          </TableCell>
        </InViewObserverWrapperTable>,
        Boolean(newRowButtonLabel && !isUserReadOnly) && (
          <InViewObserverWrapperTable key="new-row-1" dataTestId="table-new-row">
            {makeNewRowButton({
              label: newRowButtonLabel,
              handleClick: onNewRowClick,
              columns,
              isUploadLoading,
              newRow
            })}
          </InViewObserverWrapperTable>
        )
      ];
    }

    const totals = new Map();
    for (const column of columns) {
      if (column.isNumber || (column.isPercentage && column.isTotaled)) {
        totals.set(column, 0);
      }
    }

    const result = Array.from({ length: rows.length * (variant === OUTLINED ? 2 : 1) + (totalHeaderName ? 1 : 0) });
    const cellCount = columns.length + (isSelectable ? 1 : 0);
    let i = 0;
    for (const row of rows) {
      let cells;
      const isCategoryTotalRow = row.isTotal && Boolean(row.category);
      if (row.sectionHeader) {
        cells = (
          <TableCell colSpan={columns.length} className={classes.sectionHeader}>
            {row.sectionHeader}
          </TableCell>
        );
      } else if (row.newRowButtonLabel && !isUserReadOnly) {
        cells = makeNewRowButton({
          label: row.newRowButtonLabel,
          handleClick: row.onNewRowClick,
          columns,
          isUploadLoading,
          newRow
        });
      } else {
        cells = Array.from({ length: cellCount });
        let j = 0;
        if (isSelectable) {
          cells[j] = (
            <TableCell key={j++} className="checkbox">
              <Checkbox
                disabled={selectorsDisabled}
                checked={selected.includes(row)}
                icon={<RadioButtonUnchecked />}
                checkedIcon={<RadioButtonChecked />}
                onChange={() => onSelectChange?.([row], selected.includes(row))}
              />
            </TableCell>
          );
        }

        for (const column of columns) {
          const field = String(column.field);
          const fieldMetaData = row?.columnMeta?.[field];
          const { formula, entityId, jurisdictionId } = fieldMetaData || {};
          const isActiveColumn = column === activeColumn;
          const value = getCell(row, column);
          cells[j] = (
            <TableCell
              key={j++}
              align={getAlignment(column)}
              className={getClassName({ column, row, classes, isActiveRow: row === activeRow, isActiveColumn, rows })}
              width={column.width}
              style={column.getCellStyle ? column.getCellStyle(row, column) : {}}
              data-meta-field={field}
              data-meta-formula={formula}
              data-meta-rowname={row?.name}
              data-meta-entityid={entityId}
              data-meta-jurisdictionid={jurisdictionId}
              data-meta-creditname={row?.creditName}
              onClick={() => onCellClick?.(row, value, column)}
            >
              {!(column.isActions && row.isTotal) &&
                renderCell(
                  row,
                  value,
                  column,
                  failedCells,
                  onCellChange,
                  onCellOrCommentBlur,
                  renderOpts,
                  rows,
                  reportName
                )}
            </TableCell>
          );

          if (column.isNumber && totalHeaderName && !isCategoryTotalRow) {
            // FIXME : Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            totals.set(column, totals.get(column) + (Number.isNaN(value) ? 0 : value || 0));
          }

          if (column.isPercentage && column.isTotaled && totalHeaderName && !isCategoryTotalRow) {
            const parsed = Number.parseFloat(value);
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            totals.set(column, totals.get(column) + (Number.isNaN(parsed) ? 0 : parsed || 0));
          }
        }
      }

      if (variant === OUTLINED) {
        result[i] = (
          <InViewObserverWrapperTable
            key={i++}
            dataTestId={row?.creditName ?? 'creditFiller'}
            rowStyle={classes.outlineSeparator}
          >
            <TableCell colSpan={columns.length} />
          </InViewObserverWrapperTable>
        );
      }

      const classNames = [];

      if (isCategoryTotalRow) {
        classNames.push('category-total');
      }

      if ((selected || []).includes(row)) {
        classNames.push('selected');
      }

      if (row.isTotal && !isCategoryTotalRow) {
        classNames.push('total');
      }

      if (row.isSpace) {
        classNames.push('space');
      }

      if (row.isNew) {
        classNames.push('new-row');
      }

      result[i] = (
        <InViewObserverWrapperTable
          key={i++}
          dataTestId={row?.creditName}
          rowStyle={classNames.join(' ')}
          hover={!row.sectionHeader && !row.isTotal}
        >
          {cells}
        </InViewObserverWrapperTable>
      );
    }

    if (newRowButtonLabel && !isUserReadOnly) {
      result[i] = (
        <InViewObserverWrapperTable key={i} dataTestId="table-new-row-2">
          {makeNewRowButton({
            label: newRowButtonLabel,
            handleClick: onNewRowClick,
            columns,
            isUploadLoading,
            newRow
          })}
        </InViewObserverWrapperTable>
      );
      i++;
    }

    if (totalHeaderName) {
      const cells = Array.from({ length: cellCount });
      let j = 0;
      if (isSelectable) {
        cells[j] = <TableCell key={j++} className={classes.select} />;
      }

      for (const column of columns) {
        const field = String(column.field);
        const isActiveColumn = column === activeColumn;
        const isTotal = column.shouldShowRtpIcon;
        const value =
          j === 0
            ? totalHeaderName
            : // @ts-expect-error Expected 8-11 arguments, but got 3.
              !column.isActions && renderCell({ total: isTotal, renderOpts }, totals.get(column), column);

        cells[j] = (
          <TableCell
            key={j++}
            align={getAlignment(column)}
            className={getClassName({ column, row: {}, classes, isActiveRow: false, isActiveColumn })}
            width={column.width}
            data-meta-field={field}
            data-meta-rowname={totalHeaderName}
            onClick={() => onCellClick?.(totals, value, column)}
          >
            {value}
          </TableCell>
        );
      }

      result[i] = (
        <InViewObserverWrapperTable key={i} rowStyle="total">
          {cells}
        </InViewObserverWrapperTable>
      );
    }

    return result;
  }, [
    rows,
    columns,
    totalHeaderName,
    classes,
    selected,
    noRowLabel,
    variant,
    isSelectable,
    isUserReadOnly,
    selectorsDisabled,
    newRowButtonLabel,
    activeColumn,
    activeRow,
    renderOpts,
    failedCells,
    reportName,
    isUploadLoading,
    onSelectChange,
    onNewRowClick,
    onCellChange,
    onCellOrCommentBlur,
    onCellClick
  ]);

  return <MuiTableBody>{body}</MuiTableBody>;
};

export default TableBodyWithInView;

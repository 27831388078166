import { useState, useMemo, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContentAndTitle, TabTitle } from '.';

import { TableWithComment } from '../..';
import { FEDERAL_UUID, LEVELS } from '../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../hooks';
import { FederalTabProps, Step } from '../../../models';
import { setEntityCompletionStatus } from '../../../redux/entitiesCompletionStatus';
import LoadingWrapper from '../../LoadingWrapper';
import { Row } from '../../Table/Table.proptype';
import {
  EntityNumberRouteMatch,
  getTableDataByRowIdFinancialData,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails
} from '../utils';

type TaxEffectedShape = {
  amount?: number;
  comments?: string | null;
  note?: string;
};

export type TaxEffectedRow = Row & TaxEffectedShape;

const LEVEL = LEVELS.FEDERAL;
const STEP: Step = 'tax-effected';
const LevelAndStep = `${LEVEL}.${STEP}`;
const STEPS: Step[] = [STEP, 'deferred', 'rtp'];

const FederalTaxEffected = ({ entityId }: FederalTabProps) => {
  const { t } = useTranslation();
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const [rows, setRows] = useState<TaxEffectedRow[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const dispatch = useDispatch();
  const {
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3736ReservedWordValidation: showReservedWordsError,
    prov4438FilterAdjustmentsByRowId: filterAdjustmentsByRowId
  } = useFlags();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LevelAndStep, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS);
  const taxEffectedFinancialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);
  const rtpFinancialInfo = useMemo(() => tabsData['federal.rtp'] ?? [], [tabsData]);
  const deferredFinancialInfo = useMemo(() => tabsData['federal.deferred'] ?? [], [tabsData]);
  const mergedFinancialInfo = useMemo(
    () => [...taxEffectedFinancialInfo, ...rtpFinancialInfo, ...deferredFinancialInfo],
    [taxEffectedFinancialInfo, rtpFinancialInfo, deferredFinancialInfo]
  );

  const rowsWithData = useMemo(
    () => getTableDataByRowIdFinancialData(LEVEL, STEP, taxEffectedFinancialInfo, filterAdjustmentsByRowId, tabsData),
    [tabsData, taxEffectedFinancialInfo, filterAdjustmentsByRowId]
  ) as TaxEffectedRow[];

  const newRow = rows[rows.length - 1];
  const rowsToSet = useMemo(() => [...rowsWithData, ...(newRow?.isNew ? [newRow] : [])], [newRow, rowsWithData]);

  useEffect(() => {
    setRows(rowsToSet);
  }, [rowsToSet]);

  const columns = [
    {
      field: 'name',
      headerName: t('Adjustment Name'),
      isNewRowEditable: true,
      width: '30%'
    },
    {
      field: 'amount',
      headerName: t('Amount'),
      isEditable: !stepCompletionStatus.status,
      isNumber: true,
      width: '20%'
    }
  ];

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo: mergedFinancialInfo,
    hasNewRow,
    level: LEVEL,
    rows,
    setHasNewRow,
    setRows,
    step: STEP,
    t,
    showReservedWordsError,
    filterAdjustmentsByRowId
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('Tax-Effected Adjustments')}
            isCompleted={stepCompletionStatus.status}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <TableWithComment
          columns={columns}
          failedCells={failedCells}
          newRowButtonLabel={
            stepCompletionStatus.status
              ? null
              : t(hasNewRow ? 'Save New Tax-Effected Adjustment' : 'Add New Tax-Effected Adjustment')
          }
          rows={rows}
          hideActionsMenu={stepCompletionStatus.status}
          totalHeaderName={t('Total')}
          handleOnRowDelete={(params) => {
            handleOnRowDeleteForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
          onCellChange={(params) => {
            if (!isPerformanceFixEnabled) {
              handleEditRowForEntityDetails({
                ...dataForMethods,
                ...params
              });
            }
          }}
          onCellOrCommentBlur={(params) => {
            if (isPerformanceFixEnabled) {
              handleEditRowForEntityDetails({
                ...dataForMethods,
                ...params
              });
            }

            handleOnCellOrCommentBlurForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
          onNewRowClick={() => {
            handleNewRowForEntityDetails({
              ...dataForMethods
            });
          }}
        />
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default FederalTaxEffected;

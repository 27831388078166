import Typography from '@material-ui/core/Typography';
import { TFunction } from 'i18next';

import CreditNameBox from './components/CreditNameBox';
import { TaxPeriodRow } from './models';
import { getTableDataByRowIdFinancialData, getTableDataFromFinancialData } from './utils';

import { calculateCreditSum } from '../../calculations';
import { FinancialInfo, FinancialInfoTabsData, Level, Step } from '../../models';

export type CreditRow = TaxPeriodRow & { creditName?: string };

export function getCreditNamesFromFinancialData(financialInfo: FinancialInfo[]): string[] {
  const creditNamesFromCells = financialInfo.map((cell) => cell.creditName ?? 'Credit Name Missing');
  const uniqueCreditNames = [...new Set(creditNamesFromCells)];
  return uniqueCreditNames;
}

export function getRowNamesFromFinancialInfo(
  financialInfo: FinancialInfo[]
): Array<{ name: FinancialInfo['rowName'] }> {
  const rowNamesFromCells = financialInfo.map((cell) => cell.rowName) ?? [];
  const uniqueRowNames = [...new Set(rowNamesFromCells)];
  const rowNamesForTable = uniqueRowNames.map((rowName) => ({ name: rowName }));

  return rowNamesForTable;
}

export function getCreditRows({
  financialInfo,
  newRow,
  isCompleted,
  hasNewCredit,
  onNewRowClicked,
  setNewCreditName,
  t,
  isCreditNameDuplicated,
  isCreditNameReservedName,
  newCreditName,
  prov4011TotalRowFix,
  filterAdjustmentsByRowId,
  level,
  step
}: {
  financialInfo: FinancialInfo[];
  newRow: CreditRow | undefined;
  isCompleted: boolean;
  hasNewCredit: boolean;
  onNewRowClicked: (creditName: string) => void;
  setNewCreditName: (creditName: string) => void;
  t: TFunction;
  isCreditNameDuplicated?: boolean;
  isCreditNameReservedName?: boolean;
  newCreditName?: string;
  prov4011TotalRowFix?: boolean;
  filterAdjustmentsByRowId: boolean;
  level: Level;
  step: Step;
  tabsData: FinancialInfoTabsData;
}) {
  const creditNames = getCreditNamesFromFinancialData(financialInfo);

  const creditRows: TaxPeriodRow[] = [];
  const isNewRow = Boolean(newRow);

  creditNames.forEach((creditName) => {
    let rowsWithData;
    const finDataByCreditName = financialInfo.filter((cell) => cell.creditName === creditName);
    if (filterAdjustmentsByRowId) {
      rowsWithData = getTableDataByRowIdFinancialData(
        level,
        step,
        finDataByCreditName,
        filterAdjustmentsByRowId
      ) as TaxPeriodRow[];
    } else {
      const rowNames = getRowNamesFromFinancialInfo(finDataByCreditName);
      rowsWithData = getTableDataFromFinancialData(rowNames, finDataByCreditName) as TaxPeriodRow[];
    }

    const rowsWithDataWithCreditName: CreditRow[] = rowsWithData.map((row) => ({ ...row, creditName }));
    const isNewRowInCredit = newRow?.creditName === creditName;
    const isNewRowInOtherCredit = newRow && !isNewRowInCredit;

    if (isNewRowInCredit) {
      rowsWithDataWithCreditName.push(newRow!);
    }

    const newRowButtonLabel = t(isNewRow ? 'Save Tax Period' : 'Add Tax Period');

    const newRowButtonRow = {
      newRowButtonLabel,
      onNewRowClick: () => {
        onNewRowClicked(creditName);
      }
    };

    creditRows.push(
      {
        sectionHeader: (
          <Typography variant="h3" component="h4">
            {t(creditName)}
          </Typography>
        )
      },
      ...rowsWithDataWithCreditName
    );

    if (!isCompleted && !isNewRowInOtherCredit) {
      creditRows.push(newRowButtonRow);
    }

    creditRows.push({
      name: t('Total'),
      isTotal: true,
      ...calculateCreditSum({ credits: rowsWithData }, prov4011TotalRowFix)
    });
  });

  if (hasNewCredit && newRow) {
    creditRows.push(
      {
        sectionHeader: (
          <CreditNameBox
            creditName={newCreditName}
            setNewCreditName={setNewCreditName}
            isCreditNameDuplicated={isCreditNameDuplicated}
            isCreditNameReservedName={isCreditNameReservedName}
          />
        )
      },
      newRow
    );
  }

  return creditRows;
}
